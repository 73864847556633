import useCookies from 'hooks/useCookies';
import NotFoundPage from 'pages/NotFound';
import React from 'react';
import { Navigate } from 'react-router-dom';

type Props = {
  children?: React.ReactElement;
};
const LoggedOutRoute: React.FC<Props> = ({ children }) => {
  const { error_msg, error_type, token, userName } = useCookies();

  if (!error_type && !error_msg && token && userName) return <Navigate to="/portal" />;
  return (
    <div>
      {error_type && error_type !== 'email_verification' ? (
        <NotFoundPage
          status="Something went wrong"
          mainText={error_type}
          secondaryText={error_msg}
        />
      ) : (
        children
      )}
    </div>
  );
};
export default LoggedOutRoute;
