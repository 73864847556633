import * as React from 'react';
import { SVGProps } from 'react';

const RetailAnalyticsCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    viewBox="0 0 64 64"
    {...props}
  >
    <path d="M23.5 44.25a6.25 6.25 0 1 1 0 12.5 6.25 6.25 0 0 1 0-12.5zm18 0a6.25 6.25 0 1 1 0 12.5 6.25 6.25 0 0 1 0-12.5zm-18 3.5a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5zm18 0a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5zM14 8.25c1.822 0 3.234 1.475 3.67 3.202l.051.23.717 3.568H48c1.949 0 3.638 1.626 3.745 3.556l.001.257-.05.314-.317 1.53-3.673 16.48c-.365 1.609-1.889 2.774-3.518 2.858L44 40.25H23c-1.698 0-3.327-1.192-3.716-2.907L15.309 17.45l-.008-.03-.028-.136-.006-.044-.98-4.885a1.365 1.365 0 0 0-.157-.395l-.128-.2-.002-.01H7a1.75 1.75 0 0 1-.144-3.494L7 8.25zm34 10.5H19.137l3.57 17.862c.002.012.12.1.23.129l.063.009h21c.06 0 .133-.03.19-.063l.088-.057a.06.06 0 0 0 .014-.012l3.824-17.16.111-.52-.012-.023a.484.484 0 0 0-.176-.155zm-19.5 9.5a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-.75.75h-2a.75.75 0 0 1-.75-.75v-4a.75.75 0 0 1 .75-.75zm6-3a.75.75 0 0 1 .75.75v7a.75.75 0 0 1-.75.75h-2a.75.75 0 0 1-.75-.75v-7a.75.75 0 0 1 .75-.75zm6-4a.75.75 0 0 1 .75.75v11a.75.75 0 0 1-.75.75h-2a.75.75 0 0 1-.75-.75V22a.75.75 0 0 1 .75-.75z" />
  </svg>
);
export default RetailAnalyticsCard;
