import * as React from 'react';
import { SVGProps } from 'react';

const Catapult = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="m3.91 2.638 16.506 17.043c.52.39.875 1 .935 1.698l.01.212-.002.028-.004.066c-.003.09-.012.18-.025.268a2.374 2.374 0 0 1-.64 1.355 2.245 2.245 0 0 1-1.862.679l-.198-.03H2.489c-1.265 0-2.29-1.06-2.29-2.366 0-1.244.93-2.263 2.11-2.357l.18-.007h11.01L.67 5.983a2.42 2.42 0 0 1 0-3.345A2.238 2.238 0 0 1 3.764 2.5zM19.225 8.362c-2.252 0-4.078-1.872-4.078-4.181S16.973 0 19.225 0c2.252 0 4.077 1.872 4.077 4.18s-1.825 4.182-4.077 4.182z" />
  </svg>
);
export default Catapult;
