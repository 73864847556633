import PageTitle from 'components/PageTitle';
import PageContainer from 'containers/PageContainer';

const DigitalShelfPage = () => (
  <PageContainer
    navigationSection="Digital Shelf"
    title={
      <PageTitle
        title="Amazon Digital Shelf"
        subtitle="Insights for your product management to increase traffic and conversion."
      />
    }
  />
);

export default DigitalShelfPage;
