import ReactSelect from 'components/Form/FormSelect/ReactSelect';
import SlideOver from 'components/SlideOver';
import AddEmailsForm from 'containers/AddEmailsForm';
import React, { memo } from 'react';
import { ISelectorOption } from 'types/ISelectorOption';

interface AccessManagementSliderOverProps {
  slideOverOpen: boolean;
  toggleSlideOver: (isOpen: boolean) => void;
  activeReports?: ISelectorOption[];
  selectedEmails: ISelectorOption[];
  setMultiInputError: (string: string[]) => void;
  multiInputError: string[];
  setSelectedEmails: React.Dispatch<React.SetStateAction<ISelectorOption[]>>;
  setSelectedReports: React.Dispatch<React.SetStateAction<ISelectorOption[]>>;
  selectedReports: ISelectorOption[];
  onAppendEmail: (values: ISelectorOption[]) => void;
}

const AccessManagementSliderOver: React.FC<AccessManagementSliderOverProps> = ({
  slideOverOpen,
  toggleSlideOver,
  activeReports,
  selectedEmails,
  setMultiInputError,
  setSelectedEmails,
  multiInputError,
  selectedReports,
  setSelectedReports,
  onAppendEmail,
}) => (
  <SlideOver
    isOpen={slideOverOpen}
    title="ADD USERS"
    size="lg"
    subTitle="Give new and existing users access to selected modules"
    setIsOpen={() => toggleSlideOver(false)}
  >
    <div className="mt-7">
      <AddEmailsForm
        setMultiInputError={setMultiInputError}
        cancelSliderOver={() => toggleSlideOver(false)}
        multiInputError={multiInputError}
        selectedEmails={selectedEmails}
        setSelectedEmails={setSelectedEmails}
        selectedReports={selectedReports}
        extraInput={
          <div className="w-100 my-3">
            {!!activeReports?.length && (
              <ReactSelect
                label="(Optional) Give specified users access to these modules:"
                onChange={(value) => setSelectedReports(value as ISelectorOption[])}
                value={selectedReports}
                options={activeReports}
                isMulti
                placeholder="Click to select modules"
              />
            )}
          </div>
        }
        onSubmit={onAppendEmail}
      />
    </div>
  </SlideOver>
);

export default memo(AccessManagementSliderOver);
