interface PageTitleProps {
  title: string;
  subtitle?: string;
  titleStyles?: string;
  extraComponent?: React.ReactNode;
}

const PageTitle = ({ title, subtitle, titleStyles }: PageTitleProps) => (
  <div>
    <h1 className={`${titleStyles || ''} text-primary font-sharpsans_bold  text-left`}>
      {title}
    </h1>
    <p className="font-worksans_regular text-[18px] xl:text-[21px] 2xl:text-[21px] xl:leading-[24px] 2xl:leading-[24px] leading-[21px] text-left text-default_dark_gray mt-2">
      {subtitle}
    </p>
  </div>
);

export default PageTitle;
