/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo } from 'react';
import { Columns } from './Columns';

type DataTableRowProps<T extends Record<string | number, any>> = {
  item: T;
  columns: Columns<T>[];
  itemIndex: number;
  rowStyle?: string;
};

function DataTableRow<T extends Record<string | number, any>>({
  item,
  columns,
  itemIndex,
  rowStyle,
}: DataTableRowProps<T>) {
  return (
    <>
      {columns
        .filter((el) => !el.omit)
        .map((col, index) => (
          <td
            // eslint-disable-next-line react/no-array-index-key
            key={index.toString()}
            className={`py-2 pl-3 pr-3 text-sm ${rowStyle} border-b border-gray-200 ${col.className}`}
            style={{
              verticalAlign: 'middle',

              color: col.textColor?.(item, itemIndex),
            }}
          >
            {col.cell?.(item, itemIndex) ? (
              <div
                className={`overflow-hidden ${col.center ? 'flex justify-center' : ''}`}
                style={{ width: col.width }}
              >
                {col.cell?.(item, itemIndex)}
              </div>
            ) : (
              <div className="flex items-center">{item[col.selector || '']}</div>
            )}
          </td>
        ))}
    </>
  );
}

export default memo(DataTableRow);
