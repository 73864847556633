import { FC, ReactNode, useRef } from 'react';

interface Props {
  children: ReactNode;
  tooltip?: string | ReactNode;
  className?: string;
}

const ToolTip: FC<Props> = ({ children, tooltip, className }): JSX.Element => {
  const tooltipRef = useRef<HTMLSpanElement>(null);
  const container = useRef<HTMLDivElement>(null);

  return (
    <div ref={container} className="group relative inline-block">
      {children}
      {tooltip ? (
        <span
          ref={tooltipRef}
          style={{ left: '100%', top: '25%' }}
          className={`invisible group-hover:visible opacity-0 group-hover:opacity-100 transition ${
            className || 'bg-primary text-sm text-white ml-[9px]'
          }   px-2 rounded absolute top-full whitespace-nowrap font-worksans_medium text-[14px] leading-[16px] `}
        >
          {tooltip}
        </span>
      ) : null}
    </div>
  );
};

export default ToolTip;
