import React, { useEffect } from 'react';

import Cookies from 'js-cookie';

const LoginPage: React.FC = () => {
  useEffect(() => {
    window.location.href = `${process.env.REACT_APP_BASE_URL}login?apiKey=${process.env.REACT_APP_API_KEY}`;
    Cookies.remove('error_type');
    Cookies.remove('error_msg');
  }, []);

  return null;
};

export default LoginPage;
