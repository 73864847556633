export const shortenAccessManagementNames = (name: string, clientAbbreviation: string) => {
  // TODO : FIX before production
  // if (process.env.NODE_ENV === 'production') {
  const checkAdditionalNameExists = name.split(`Enalytics-Access-${clientAbbreviation}-`);
  if (checkAdditionalNameExists.length > 1) {
    return checkAdditionalNameExists[1];
  }
  return name;
  // }

  // const checkAdditionalNameExists = name?.split(
  //   `Staging_Enalytics-Access-${clientAbbreviation}-`,
  // );

  // if (checkAdditionalNameExists.length > 1) {
  //   return checkAdditionalNameExists[1];
  // }
  // return name;
};
