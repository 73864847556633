import React from 'react';

const ClickableDiv: React.ForwardRefRenderFunction<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ children, ...props }, ref) => (
  <div
    key={props.key}
    onClick={props.onClick}
    tabIndex={-1}
    role="button"
    onKeyUp={() => props.onClick}
    className="cursor-pointer"
    ref={ref}
    {...props}
  >
    {children}
  </div>
);

export default React.forwardRef(ClickableDiv);
