import React, { useEffect } from 'react';

import Button from 'components/Button';
import { showToast } from 'helpers/ToastHelper';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { clientApi } from '../../api';
import CatapultOld from '../../assets/svg/CatapultOld';

const VerifyEmailPage: React.FC = () => {
  const token = Cookies.get('token');
  const error_msg = Cookies.get('error_msg');
  const error_type = Cookies.get('error_type');
  const navigate = useNavigate();

  useEffect(() => {
    if (error_type !== 'email_verification' && !error_msg) {
      navigate('/');
    }
  }, [error_type, error_msg]);

  const onResendEmail = async () => {
    try {
      if (error_type === 'email_verification') {
        await clientApi.post('send_email_verification', { token });
        showToast('success', 'Email sent successfully !');
        Cookies.remove('error_type');
        Cookies.remove('error_msg');
      }
    } catch (error) {
      showToast('error', error?.message);
    }
  };
  return (
    <div>
      <div className="h-screen pb-14 bg-right">
        <div className="w-full container mx-auto p-6 border-b-2">
          <div className="w-full flex items-center justify-between">
            <div>
              <CatapultOld height={30} width={400} />
            </div>
          </div>
        </div>
        <div className="container md:pt-36 px-6 mx-auto flex flex-wrap flex-col">
          <div>{error_msg}</div>

          <div className="mb-10 mt-10">
            <div className="mb-3">You didn&apos;t receive an email verification ? </div>
          </div>
          <div className="flex">
            <Button
              type="button"
              btnType="primary"
              main="Resend Email"
              onClick={onResendEmail}
            />
            <Link
              to="/"
              // eslint-disable-next-line max-len
              className="rounded-md ms-2 px-3.5 py-2.5 text-sm font-semibold bg-white hover:bg-gray-50 shadow-sm ring-2 ring-inset ring-primary"
            >
              Go back home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailPage;
