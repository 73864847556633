import PageTitle from 'components/PageTitle';
import PageContainer from 'containers/PageContainer';

const BespokeSolutionsPage = () => (
  <PageContainer
    navigationSection="Custom"
    title={<PageTitle title="Bespoke Solutions" subtitle="Access your custom BI reports" />}
  />
);

export default BespokeSolutionsPage;
