import InformationIcon from 'assets/svg/InformationIcon';
import React from 'react';

interface AccessRequiredCardProps {
  adminsList?: string[];
}
const AccessRequiredCard: React.FC<AccessRequiredCardProps> = ({ adminsList }) => (
  <div className="mt-10">
    <div className="bg-default_gray p-7 rounded flex flex-row ">
      <InformationIcon width={40} height={40} className="fill-primary mr-2 items-start" />
      <div className=" font-worksans_medium mt-2">
        <div className="text-[20px] leading-[24px] font-worksans_bold">
          You can request access
        </div>
        <div className="mt-5">
          Your organization has licensed this module, but you don&apos;t have access yet.
          <div className="mt-5">
            To request access, you can contact:
            {adminsList?.length > 0 ? (
              <div>
                {adminsList.map((el) => (
                  <div className="text-light_blue">{el}</div>
                ))}
              </div>
            ) : (
              <div className="text-light_blue">support@catapult-analytics.com</div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AccessRequiredCard;
