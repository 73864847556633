import * as React from 'react';
import { SVGProps } from 'react';

const CatapultOld = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 30" fill="none" {...props}>
    <g fill="#000" clipPath="url(#a)">
      <path d="M72.307 20.447c-.236 1.086-.854 1.953-1.85 2.596-1 .646-2.26.969-3.79.969-1.88 0-3.348-.58-4.405-1.737-1.058-1.158-1.585-2.75-1.585-4.775 0-2.025.527-3.614 1.585-4.775 1.057-1.158 2.525-1.736 4.405-1.736 1.526 0 2.79.322 3.79.968 1 .646 1.614 1.51 1.85 2.596h11.72c-.323-4.37-2.019-7.722-5.09-10.056C75.868 2.167 71.763 1 66.624 1c-5.14 0-9.326 1.554-12.644 4.664C50.658 8.774 49 12.72 49 17.5s1.658 8.726 4.979 11.836C57.297 32.446 61.513 34 66.623 34s9.244-1.167 12.315-3.497c3.067-2.33 4.766-5.683 5.09-10.053H72.306v-.003ZM93.1 2.1 82.526 32.9H94.07l.836-3.386h9.031l.836 3.386h11.543l-10.57-30.8H93.1Zm3.743 19.712 2.555-10.207 2.555 10.207h-5.11ZM139.142 2.1h-26.127v9.459h7.447V32.9h11.234V11.56h7.446v-9.46ZM146.56 2.1l-10.573 30.8h11.543l.836-3.386h9.031l.836 3.386h11.543L159.204 2.1H146.56Zm3.743 19.712 2.555-10.207 2.555 10.207h-5.11ZM188.273 2.1H171.62v30.8h11.234v-7.172h5.419c3.406 0 6.249-1.123 8.524-3.366 2.276-2.243 3.415-5.051 3.415-8.426s-1.13-6.227-3.391-8.47c-2.261-2.243-5.11-3.366-8.548-3.366Zm-.42 14.3c-.603.646-1.372.97-2.313.97h-2.686v-6.865h2.733c.909 0 1.666.323 2.269.97.603.645.904 1.466.904 2.464s-.303 1.818-.904 2.464l-.003-.003ZM219.641 21.282c0 .88-.294 1.621-.88 2.223-.588.603-1.366.902-2.336.902-.97 0-1.748-.3-2.334-.902-.588-.6-.88-1.341-.88-2.223V2.1h-11.234v19.886c0 3.608 1.3 6.477 3.898 8.601 2.599 2.127 6.116 3.189 10.553 3.189 4.437 0 7.953-1.062 10.552-3.189 2.599-2.127 3.898-4.993 3.898-8.6V2.1h-11.234v19.182h-.003ZM246.077 2.1H234.84v30.8h23.709v-9.462h-12.472V2.1Z" />
      <path d="M253.873 2.1v9.459h7.447V32.9h11.233V11.56H280v-9.46h-26.127Z" />
    </g>
    <path
      fill="#000"
      d="M33.228 30.83c0-.014.003-.027.003-.04a3.4 3.4 0 0 0-1.346-2.724L8.345 3.763a3.195 3.195 0 0 0-4.62 0c-1.275 1.316-1.275 3.451 0 4.769L22.02 27.418h-15.7c-1.805 0-3.267 1.51-3.267 3.372 0 1.863 1.462 3.373 3.266 3.373h23.019a3.198 3.198 0 0 0 2.937-.925 3.385 3.385 0 0 0 .914-1.933c.018-.125.03-.252.036-.381 0-.031.005-.062.005-.092v-.002ZM30.185 11.924c3.212 0 5.815-2.67 5.815-5.962C36 2.669 33.397 0 30.185 0c-3.211 0-5.814 2.67-5.814 5.962 0 3.293 2.603 5.962 5.814 5.962Z"
    />
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M49 1h231v33H49z" />
      </clipPath>
    </defs>
  </svg>
);
export default CatapultOld;
