import { PlusCircleIcon, XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import ErrorText from 'components/Form/ErrorText';
import {
  clearIndicatorStyles,
  controlStyles,
  dropdownIndicatorStyles,
  groupHeadingStyles,
  indicatorsContainerStyles,
  indicatorSeparatorStyles,
  menuStyles,
  multiValueLabelStyles,
  multiValueRemoveStyles,
  multiValueStyles,
  noOptionsMessageStyles,
  optionStyles,
  placeholderStyles,
  selectInputStyles,
  singleValueStyles,
  valueContainerStyles,
} from 'components/Form/FormSelect/styles';
import { FieldError, FieldErrors, Merge } from 'react-hook-form';
import Select, {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  MenuPlacement,
  MultiValueRemoveProps,
} from 'react-select';
import Creatable from 'react-select/creatable';
import { ISelectorOption } from 'types/ISelectorOption';
import Label from '../Label';

export const DropdownIndicatorCircle = (props: DropdownIndicatorProps) => {
  if (props?.selectProps?.isDisabled) return null;
  return (
    <components.DropdownIndicator {...props}>
      <PlusCircleIcon className="h-5 w-5" />
    </components.DropdownIndicator>
  );
};
export const DropdownIndicator = (props: DropdownIndicatorProps) => {
  if (props?.selectProps?.isDisabled) return null;
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon className="h-4 w-4" />
    </components.DropdownIndicator>
  );
};
export const ClearIndicator = (props: ClearIndicatorProps) => (
  <components.ClearIndicator {...props}>
    <XMarkIcon className="h-4 w-4" />
  </components.ClearIndicator>
);

export const MultiValueRemove = (props: MultiValueRemoveProps) => (
  <components.MultiValueRemove {...props}>
    <XMarkIcon className="h-4 w-4" />
  </components.MultiValueRemove>
);

export interface ReactSelectProps<T1 = string, R1 = string, T2 = string, R2 = string> {
  id?: string;
  options: ISelectorOption[];
  isMulti?: boolean;
  reverseIndicator?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: unknown, setValue: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: FieldError | Merge<FieldError, FieldErrors<any>>;
  value?: ISelectorOption | ISelectorOption[] | unknown;
  setValue?: React.Dispatch<React.SetStateAction<ISelectorOption<string>[]>>;
  menuPlacement?: MenuPlacement;
  menuPortalTarget?: HTMLElement | null | undefined;
  withErrorLabel?: boolean;
  creatable?: boolean;
  closeMenuOnScroll?: boolean | ((event: Event) => boolean) | undefined;
  validation?: Record<string, unknown>;
  isDisabled?: boolean;
  defaultValue?: ISelectorOption;
  hideSelectedOptions?: boolean;
  apiCall?: (value: T1) => Promise<R1>;
  secondaryApiCall?: (value: T2) => Promise<R2>;
  setMultiInputError?: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  multiInputError?: string[];
  withoutBorder?: boolean;
  setInputValue?: React.Dispatch<React.SetStateAction<string>>;
  inputValue?: string;
}

export default function ReactSelect<T1, R1, T2, R2>({
  error,
  creatable,
  defaultValue,
  withoutBorder = false,
  reverseIndicator = false,
  label,
  ...props
}: ReactSelectProps<T1, R1, T2, R2>) {
  return (
    <>
      <Label label={label} name={label} />
      {creatable ? (
        <Creatable
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isClearable
          isSearchable
          unstyled
          styles={{
            input: (base) => ({
              ...base,
              'input:focus': {
                boxShadow: 'none',
              },
            }),
            multiValueLabel: (base) => ({
              ...base,
              whiteSpace: 'normal',
              overflow: 'visible',
            }),
            control: (base) => ({
              ...base,
              transition: 'none',
            }),
          }}
          components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
          classNames={{
            control: ({ isFocused }) =>
              clsx(
                error && !isFocused ? controlStyles.error : controlStyles.base,
                isFocused ? controlStyles.focus : controlStyles.nonFocus,
                isFocused && error ? controlStyles.focusWithError : controlStyles.base,
                controlStyles.base,
              ),
            placeholder: () => placeholderStyles,
            input: () => selectInputStyles,
            valueContainer: () => valueContainerStyles,
            singleValue: () => singleValueStyles,
            multiValue: () => multiValueStyles,
            multiValueLabel: () => multiValueLabelStyles,
            multiValueRemove: () => multiValueRemoveStyles,
            indicatorsContainer: () => indicatorsContainerStyles,
            clearIndicator: () => clearIndicatorStyles,
            indicatorSeparator: () => indicatorSeparatorStyles,
            dropdownIndicator: () => dropdownIndicatorStyles,
            menu: () => menuStyles,
            groupHeading: () => groupHeadingStyles,
            option: ({ isFocused, isSelected }) =>
              clsx(
                isFocused && optionStyles.focus,
                isSelected && optionStyles.selected,
                optionStyles.base,
              ),
            noOptionsMessage: () => noOptionsMessageStyles,
          }}
          {...props}
        />
      ) : (
        <Select
          defaultValue={defaultValue}
          isClearable={props.isClearable}
          isSearchable
          closeMenuOnSelect={!props.isMulti}
          hideSelectedOptions={props.hideSelectedOptions || !props.isMulti}
          unstyled
          styles={{
            input: (base) => ({
              ...base,
              'input:focus': {
                boxShadow: 'none',
              },
            }),
            multiValueLabel: (base) => ({
              ...base,
              whiteSpace: 'normal',
              overflow: 'visible',
            }),
            control: (base) => ({
              ...base,
              transition: 'none',
              flexDirection: reverseIndicator ? 'row-reverse' : 'row',
            }),
          }}
          components={{
            DropdownIndicator: reverseIndicator ? DropdownIndicatorCircle : DropdownIndicator,
            ClearIndicator,
            MultiValueRemove,
          }}
          classNames={{
            control: ({ isFocused, isDisabled }) =>
              !withoutBorder &&
              clsx(
                error && !isFocused ? controlStyles.error : controlStyles.base,
                isFocused ? controlStyles.focus : controlStyles.nonFocus,
                isFocused && error ? controlStyles.focusWithError : controlStyles.base,
                controlStyles.base,
                isDisabled && controlStyles.disabledInputStyles,
              ),
            placeholder: () => placeholderStyles,
            input: ({ isDisabled }) =>
              isDisabled ? controlStyles.disabledInputStyles : selectInputStyles,
            valueContainer: () => valueContainerStyles,
            singleValue: () => singleValueStyles,
            multiValue: () => multiValueStyles,
            multiValueLabel: () => multiValueLabelStyles,
            multiValueRemove: () => multiValueRemoveStyles,
            indicatorsContainer: ({ selectProps }) =>
              withoutBorder && !selectProps.isDisabled ? '' : indicatorsContainerStyles,
            clearIndicator: ({ selectProps }) =>
              !selectProps.isDisabled && clearIndicatorStyles,
            indicatorSeparator: ({ selectProps }) =>
              !withoutBorder && !selectProps.isClearable && indicatorSeparatorStyles,
            dropdownIndicator: ({ selectProps }) =>
              !selectProps.isDisabled && dropdownIndicatorStyles,
            menu: () => clsx(menuStyles, reverseIndicator ? 'mt-0 left-[33px]' : ''),
            groupHeading: () => groupHeadingStyles,
            option: ({ isFocused, isSelected }) =>
              clsx(
                isFocused && optionStyles.focus,
                isSelected && optionStyles.selected,
                optionStyles.base,
              ),
            noOptionsMessage: () => noOptionsMessageStyles,
          }}
          {...props}
        />
      )}

      <ErrorText className={withoutBorder ? 'ms-2' : ''} error={error} />
    </>
  );
}
