interface MsgProps {
  msg: string;
  description?: string;
}

const Msg = ({ msg, description }: MsgProps) => (
  <div className="flex flex-col">
    <p className="text-sm font-medium text-gray-900">{msg}</p>
    {description && <p className="mt-1 text-sm text-gray-500">{description}</p>}
  </div>
);

export default Msg;
