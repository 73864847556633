import * as React from 'react';
import { SVGProps } from 'react';

const AccessManagement = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    {...props}
  >
    <g fill="none" fillRule="evenodd" strokeWidth={2}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M24 30a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM16 38c0-4.444 3.556-8 8-8s8 3.556 8 8z"
      />
      <path d="M12 18h24a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H12a2 2 0 0 1-2-2V20a2 2 0 0 1 2-2z" />
      <g strokeLinecap="round" strokeLinejoin="round">
        <path d="M13 14h22M17 10h14" />
      </g>
    </g>
  </svg>
);
export default AccessManagement;
