import { Navigate, useNavigate } from 'react-router-dom';

import SideBar from 'components/SideBar';
import useCookies from 'hooks/useCookies';
import React from 'react';
import { ReportsProvider } from 'context/ReportsContext';

type Props = {
  children?: React.ReactElement;
  withSideBar?: boolean;
};

const PrivateRoute: React.FC<Props> = ({ children, withSideBar = true }) => {
  const { userName, token, error_type, error_msg } = useCookies();

  const navigate = useNavigate();
  React.useEffect(() => {
    if (error_type === 'email_verification' && error_msg) {
      navigate('/verify-email');
    }
  }, [error_type, error_msg]);

  if (!token || !userName) return <Navigate to="/" />;

  return (
    <ReportsProvider>
      {withSideBar ? (
        <div>
          <SideBar>{children}</SideBar>
        </div>
      ) : (
        <div>{children}</div>
      )}
    </ReportsProvider>
  );
};
export default PrivateRoute;
