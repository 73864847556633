import { XMarkIcon } from '@heroicons/react/24/outline';

const CustomCloseButton = () => (
  <button
    type="button"
    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
  >
    <span className="sr-only">Close</span>
    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
  </button>
);

export default CustomCloseButton;
