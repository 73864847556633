/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { CheckIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { useReportsContext } from 'context/ReportsContext';
import React, { useState } from 'react';
import Select from 'react-select';
import { ISelectorOption } from 'types/ISelectorOption';
import {
  controlStyles,
  dropdownIndicatorStyles,
  menuStyles,
  optionStyles,
  selectInputStyles,
  singleValueStyles,
  valueContainerStyles,
} from './styles';
import { useLocation, useSearchParams } from 'react-router-dom';
import { checkIfSectionPage } from 'helpers/utils';

const Dropdown = () => {
  const [focus, setFocus] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selected, setSelected] = useState<ISelectorOption>();
  const { navigationData, setSelectedClient } = useReportsContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const options = React.useMemo(
    () =>
      navigationData?.reports_accesses?.map((el) => ({
        value: el.client,
        label: el.client,
      })),
    [navigationData],
  );

  React.useEffect(() => {
    if (options?.length > 0) {
      if (searchParams.get('client')) {
        const selectedClient = options.find((el) => el.value === searchParams.get('client'));
        if (selectedClient) {
          setSelected(selectedClient);
        } else {
          setSelected(options[0]);
          setSearchParams({ client: options[0].value });
        }
      } else {
        setSelected(options[0]);
        setSearchParams({ client: options[0].value });
      }
      setIsSelected(true);
    }
  }, [options, searchParams]);

  const handleChange = (selectedOption: ISelectorOption) => {
    setSelected(selectedOption);
    setIsSelected(true);
    setIsHovered(false);
    const client = navigationData?.reports_accesses?.find(
      (el) => el.client === selectedOption.value,
    );
    if (client) {
      setSelectedClient(client);
      setSearchParams({ client: client.client });
    }
  };

  const handleClick = () => {
    if (isSelected) {
      setIsSelected(false);
    }
    setFocus(true);
  };

  const formatOptionLabel = ({ value, label }, { context }) => (
    <div className={`flex ${context === 'menu' && optionStyles}`}>
      <div>{label}</div>
      {context === 'menu' && selected?.value === value && (
        <div className="ml-auto">
          <CheckIcon className="text-default_cool_gray h-5 w-5" />
        </div>
      )}
    </div>
  );

  return (
    <div>
      {options.length > 1 && (
        <div
          style={{ width: '200px' }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleClick}
        >
          <Select
            onBlur={() => {
              setFocus(false);
              setIsHovered(false);
            }}
            options={options}
            isSearchable
            value={selected}
            onChange={handleChange}
            unstyled
            menuPortalTarget={document.body}
            classNames={{
              control: () =>
                clsx(
                  controlStyles(checkIfSectionPage(pathname)).base,
                  focus && !isSelected && controlStyles(checkIfSectionPage(pathname)).focus,
                ),
              input: () => selectInputStyles,
              valueContainer: () =>
                clsx(
                  valueContainerStyles,
                  ((focus && !isSelected) || isHovered) && 'text-primary font-bold ',
                ),
              singleValue: () => singleValueStyles,

              dropdownIndicator: (state) =>
                clsx(
                  state.selectProps.menuIsOpen && 'transform rotate-180',
                  ((focus && !isSelected) || isHovered) && 'text-primary',
                  dropdownIndicatorStyles,
                ),
              menu: () => menuStyles,
            }}
            formatOptionLabel={formatOptionLabel}
          />
        </div>
      )}
    </div>
  );
};

export default Dropdown;
