import clsx from 'clsx';
import useNavigationCard from './useNavigationCard';

interface NavigationCardProps {
  reportName: string;
  disabled?: boolean;
  section: string;
}

const NavigationCard = ({ reportName, disabled = false, section }: NavigationCardProps) => {
  const { handleMouseEnter, LeftIconComponent, handleMouseLeave, rightIcon, isHovered } =
    useNavigationCard({ disabled, section });
  return (
    <div
      className="xs:w-[80vw] xs:h-[100%] sm:w-[17vw] sm:h-[17vw] md:w-[15vw] md:h-[15vw] lg:w-[15vw] lg:h-[15vw] xl:w-[15vw] xl:h-[15vw] 2xl:w-[15vw] 2xl:h-[15vw] flex flex-col justify-between items-start rounded-lg border-[3px] border-solid border-default_gray p-5 xl:p-6 hover:bg-secondary hover:border-secondary"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <h1
        className={clsx(
          disabled && 'text-default_dark_gray',
          disabled && isHovered && 'text-disabled_color',
          'text-black text-[21px] tracking-[0.3px] leading-[26.5px]  md:text-[24px] md:tracking-[0.3px] md:leading-[30px]  xl:text-[28px] xl:tracking-[0.3px] xl:leading-[35px] text-left font-sharpsans_bold font-medium',
        )}
      >
        {reportName}
      </h1>
      <div className="flex items-center  justify-between w-full">
        <LeftIconComponent
          className={clsx(
            disabled && 'fill-default_dark_gray',
            disabled && isHovered && 'fill-disabled_color',
            'w-[48px] h-[48px] md:w-[56px] md:h-[56px] xl:w-[64px] xl:h-[64px]',
          )}
        />
        {rightIcon}
      </div>
    </div>
  );
};

export default NavigationCard;
