import * as React from 'react';

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  btnType?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light';
  textStyles?: string;
  containerStyles?: string;
  main?: string | React.ReactNode;
  btnSize?: 'lg' | 'md' | 'sm';
  loading?: boolean;
}

const Button = ({
  btnType,
  textStyles,
  containerStyles,
  btnSize = 'md',
  main,
  loading,
  ...props
}: ButtonProps) => {
  const styles = React.useMemo(() => {
    let size = '';
    let style = '';
    switch (btnSize) {
      case 'lg':
        size = 'h-12 px-6';
        break;
      case 'md':
        size = 'h-10 px-5';
        break;
      case 'sm':
        size = 'h-8 px-4';
        break;
      default:
        size = 'h-10 px-5';
        break;
    }
    switch (btnType) {
      case 'primary':
        style = 'bg-primary hover:bg-gray-800 ring-1 ring-inset ring-primary';
        break;
      case 'secondary':
        style = 'bg-secondary hover:bg-neon_light ring-1 ring-inset ring-secondary';
        break;
      case 'success':
        style = 'bg-success hover:bg-green-300 ring-1 ring-inset ring-gray-300';
        break;
      case 'danger':
        style = 'bg-danger hover:bg-red-300';
        break;
      case 'warning':
        style = 'bg-yellow-600 hover:bg-yellow-300';
        break;
      case 'info':
        style = 'bg-navy hover:bg-navy_light_semi';
        break;
      case 'light':
        style = 'bg-white hover:bg-gray-50 shadow-sm ring-2 ring-inset ring-primary';
        break;
      default:
        style = 'bg-primary hover:bg-gray-800';
        break;
    }
    return {
      style,
      size,
    };
  }, [btnSize, btnType]);
  return (
    <button
      type="button"
      className={`flex flex-row relative justify-center items-center rounded-md py-3 text-sm font-semibold text-white shadow-sm  ${
        styles.style
      } ${styles.size} ${containerStyles || 'w-max'} ${props.disabled ? 'opacity-50' : ''}`}
      {...props}
      disabled={props.disabled || loading}
    >
      <div className={textStyles}>
        {loading ? (
          <div className="flex">
            <svg
              className={`animate-spin -ml-1 mr-3 h-5 w-5 ${textStyles}`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
            Processing...
          </div>
        ) : (
          <div>
            {typeof main === 'string' ? <span className={textStyles}>{main}</span> : main}
          </div>
        )}
      </div>
    </button>
  );
};
export default Button;
