import * as React from 'react';
import { SVGProps } from 'react';

const Unlocked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M24 7.5c4.087 0 7.37 3.17 7.496 7.254L31.5 15v1a1.5 1.5 0 0 1-2.993.144L28.5 16v-1c0-2.539-1.981-4.5-4.5-4.5-2.447 0-4.386 1.85-4.495 4.284L19.5 15v5.5h11.864c1.656 0 3.038 1.259 3.131 2.88l.005.176v10.888c0 1.705-1.42 3.056-3.136 3.056H16.636c-1.715 0-3.136-1.35-3.136-3.056V23.556c0-1.705 1.42-3.056 3.136-3.056l-.136.003V15c0-4.202 3.33-7.5 7.5-7.5zm7.364 16H16.636c-.091 0-.136.042-.136.056v10.888c0 .014.045.056.136.056h14.728c.091 0 .136-.042.136-.056V23.556c0-.014-.045-.056-.136-.056zM24 25.75a2.25 2.25 0 0 1 1.5 3.927V31a1.5 1.5 0 0 1-2.993.144L22.5 31v-1.322A2.25 2.25 0 0 1 24 25.75z"
    />
  </svg>
);
export default Unlocked;
