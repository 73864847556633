import Cookies from 'js-cookie';

const useCookies = () => {
  const token = Cookies.get('token');
  const userName = Cookies.get('uname');
  const error_type = Cookies.get('error_type');
  const error_msg = Cookies.get('error_msg');
  const email = Cookies.get('email');

  const removeErrors = () => {
    Cookies.remove('error_type');
    Cookies.remove('error_msg');
  };

  return {
    token,
    userName,
    error_msg,
    error_type,
    removeErrors,
    email,
  };
};

export default useCookies;
