import * as React from 'react';

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export interface SearchBarProps extends InputProps {
  inputIcon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
}

const SearchBar: React.FC<SearchBarProps> = ({
  name,

  inputIcon,
  iconPosition = 'left',
  ...props
}) => (
  <div className="relative rounded-md shadow-sm">
    {inputIcon && (
      <div
        className={`pointer-events-none absolute inset-y-0 ${
          iconPosition === 'left' ? 'left-0' : 'right-0 pr-3'
        } flex items-center pl-3`}
      >
        {inputIcon}
      </div>
    )}
    <input
      {...props}
      name={name}
      className={` font-semibold disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 block rounded-md border w-full border-gray-300 h-10 ${
        inputIcon && iconPosition === 'left' && 'pl-10'
      } bg-white focus:outline-none text-sm text-gray-700 py-2 px-2 leading-5 transition-colors duration-150 ease-in-out `}
    />
  </div>
);

export default SearchBar;
