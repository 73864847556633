import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import Msg from 'components/Msg';
import { toast } from 'react-toastify';

export const showToast = (type: string, message: string, description?: string) => {
  switch (type) {
    case 'success':
      toast.success(<Msg msg={message} description={description} />, {
        icon: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
      });
      break;
    case 'error':
      toast.error(<Msg msg={message} description={description} />, {
        icon: <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
      });
      break;
    case 'warning':
      toast.warning(<Msg msg={message} description={description} />, {
        icon: (
          <ExclamationTriangleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
        ),
      });
      break;
    case 'info':
      toast.info(<Msg msg={message} description={description} />, {
        icon: <InformationCircleIcon className="h-6 w-6 text-blue-400" aria-hidden="true" />,
      });
      break;
    default:
      toast.info(<Msg msg={message} description={description} />, {
        icon: <InformationCircleIcon className="h-6 w-6 text-blue-400" aria-hidden="true" />,
      });
      break;
  }
};
