import React from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { IAccessManagement } from 'Models/IClient';
import PageTitle from 'components/PageTitle';
import { ISelectorOption } from 'types/ISelectorOption';
import Button from 'components/Button';
import AccessManagementSliderOver from 'components/AccessManagementSliderOver';
import { MagnifyingGlassIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import Blocker from 'components/Blocker';
import useAddMembersForm from './useAddMembersForm';
import Modal from 'components/Modal';
import VirtualizedDataTable from 'components/DataTable/VirtualizedDataTable';
import SearchBar from 'components/SearchBar';
import MultiToolTip from 'components/MultiToolTip';

interface AddMembersFormProps {
  onSubmit: SubmitHandler<{ accessManagement?: IAccessManagement<ISelectorOption>[] }>;
  activeReports?: ISelectorOption[];
  defaultValues?: { accessManagement?: IAccessManagement<ISelectorOption>[] };
  title: string;
  loadingSubmit: boolean;
  isFetching: boolean;
}
const AddMembersForm: React.FC<AddMembersFormProps> = ({
  onSubmit,
  title,
  activeReports,
  defaultValues,
  loadingSubmit,
}) => {
  const {
    dirtyFields,
    getUserStatusColor,
    handleSubmit,
    isDirty,
    managementAccessColumns,
    multiInputError,
    onAppendEmail,
    reset,
    selectedEmails,
    slideOverOpen,
    toggleSlideOver,
    formMethods,
    selectedReports,
    setMultiInputError,
    setSelectedReports,
    setSelectedEmails,
    formValues,
    onAddUser,
    onCloseModal,
    isConfirmationModal,
    onSearch,
    virtuoso,
    searchIndex,
    searchQuery,
  } = useAddMembersForm({ defaultValues, activeReports });

  return (
    <div>
      <div className="mb-10 xl:mb-12">
        <PageTitle subtitle="Edit user permissions to control module access. " title={title} />
      </div>
      {activeReports?.length !== 0 && activeReports ? (
        <div>
          <AccessManagementSliderOver
            {...{
              multiInputError,
              onAppendEmail,
              selectedEmails,
              selectedReports,
              setMultiInputError,
              setSelectedEmails,
              setSelectedReports,
              slideOverOpen,
              toggleSlideOver,
              activeReports,
            }}
          />

          <FormProvider {...formMethods}>
            <div>
              {!!formValues?.length && activeReports && activeReports.length !== 0 && (
                <div className="mt-5">
                  <div className="flex items-center">
                    <div className="mr-3">
                      <Button
                        type="button"
                        main={
                          <div className="flex items-center">
                            <div>Add users</div>
                            <UserPlusIcon className="h-4 w-4 ms-1" aria-hidden="true" />
                          </div>
                        }
                        btnType="primary"
                        onClick={onAddUser}
                      />
                    </div>
                    <div className="w-[100%] mr-3">
                      <SearchBar
                        name="Search"
                        value={searchQuery}
                        ref={virtuoso}
                        onChange={onSearch}
                        placeholder="Find existing users"
                        inputIcon={<MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />}
                        iconPosition="left"
                      />
                    </div>

                    <div>
                      <div className="flex justify-between space-x-3 items-end ">
                        <div className="flex space-x-3">
                          <MultiToolTip
                            id="colors_tooltip"
                            place="top"
                            hoverComponent={
                              <Button
                                onClick={handleSubmit((values) => {
                                  onSubmit(values);
                                })}
                                btnType="secondary"
                                type="button"
                                textStyles="text-black"
                                loading={loadingSubmit}
                                disabled={loadingSubmit}
                                main={
                                  <div className="flex items-center">
                                    <div>Save</div>
                                  </div>
                                }
                              />
                            }
                          >
                            <div>
                              <div className="ms-1">
                                <li className="flex items-center">
                                  <div className="h-3 w-3 bg-secondary rounded-sm me-1" />
                                  User will be added
                                </li>
                                <li className="flex items-center">
                                  <div className="h-3 w-3 bg-default_gray rounded-sm me-1" />
                                  User permissions will be updated
                                </li>
                                <li className="flex items-center">
                                  <div className="h-3 w-3 bg-burgundy_light rounded-sm me-1" />
                                  User will be removed
                                </li>
                              </div>
                            </div>
                          </MultiToolTip>
                          <Button
                            type="button"
                            main="Reset"
                            btnType="light"
                            disabled={loadingSubmit}
                            textStyles="text-primary"
                            onClick={() => reset(defaultValues)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={searchIndex !== null ? 'fading-animation' : 'hidden'}>
                    <VirtualizedDataTable
                      ref={virtuoso}
                      rowStyle={(row, index) =>
                        getUserStatusColor(
                          row,
                          (dirtyFields?.accessManagement?.[index]
                            ?.accessGroups as unknown as boolean) ||
                            (dirtyFields?.accessManagement?.[index]
                              ?.isAdmin as unknown as boolean),
                          index,
                        )
                      }
                      data={formValues || []}
                      columns={managementAccessColumns}
                      className="2xl:h-[62vh] h-[53vh]"
                    />
                  </div>
                  {searchIndex === null && (
                    <div className="flex justify-center items-center h-[62vh]  fading-animation">
                      <div className="flex flex-col items-center">
                        <div className="text-2xl font-worksans_medium text-default_cool_gray">
                          {`User ${searchQuery} was not found.`}
                        </div>
                        <div className="text-base font-worksans_regular text-default_cool_gray">
                          Please check the email address or add a new user.
                        </div>
                      </div>
                    </div>
                  )}

                  <Modal
                    title="Changes must be saved before adding new users."
                    containerStyleOrSize="lg"
                    closeModal={onCloseModal}
                    isOpen={isConfirmationModal}
                    withCloseIcon={false}
                  >
                    <div className="flex justify-end mt-4">
                      <div>
                        <Button
                          onClick={onCloseModal}
                          type="submit"
                          btnType="light"
                          btnSize="md"
                          main="Go back"
                          textStyles="text-black"
                        />
                      </div>
                      <div className="ms-2">
                        <Button
                          onClick={handleSubmit(async (values) => {
                            onCloseModal();
                            onSubmit(values);
                          })}
                          type="submit"
                          loading={loadingSubmit}
                          btnType="primary"
                          btnSize="md"
                          main="Save and continue"
                        />
                      </div>
                    </div>
                  </Modal>
                </div>
              )}
            </div>
            <Blocker reason={isDirty} />
          </FormProvider>
        </div>
      ) : (
        <p className="font-worksans_medium text-[18px] leading-6 text-left text-default_cool_gray mt-10 xl:mt-12">
          No active report available.
        </p>
      )}
    </div>
  );
};

export default AddMembersForm;
