import { FieldError, FieldErrors, Merge } from 'react-hook-form';

interface ErrorTextProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: FieldError | Merge<FieldError, FieldErrors<any>> | string;
  className?: string;
}
const ErrorText = ({ error, className }: ErrorTextProps) => (
  <div>
    {error && (
      <p className={`text-sm text-burgundy  ${className}`}>
        {typeof error === 'string' ? error : error.message?.toString()}
      </p>
    )}
  </div>
);

export default ErrorText;
