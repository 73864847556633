import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { IClient } from 'Models/IClient';
import { ClientAccess, Report, ReportsAccess } from 'Models/IReportsAccess';
import { fetchAccessManagement, fetchProductsDescription } from 'api/requests/deploybot';
import { fetchPermissions } from 'api/requests/general';
import useCookies from 'hooks/useCookies';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface ReportsContextType {
  navigationData: ReportsAccess;
  setNavigationData: React.Dispatch<React.SetStateAction<ReportsAccess>>;
  productsDescription: Report[];
  setProductsDescription: React.Dispatch<React.SetStateAction<Report[]>>;
  selectedClient: ClientAccess;
  setSelectedClient: React.Dispatch<React.SetStateAction<ClientAccess>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setLoadingProducts: React.Dispatch<React.SetStateAction<boolean>>;
  loadingProducts: boolean;
  clientData?: UseQueryResult<IClient, unknown>;
}

const ReportsContext = createContext<ReportsContextType | undefined>(undefined);

export const ReportsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const [navigationData, setNavigationData] = React.useState<ReportsAccess>({
    reports_accesses: [],
    auth0_roles: [],
  });

  const [productsDescription, setProductsDescription] = useState<Report[]>([]);
  const [selectedClient, setSelectedClient] = useState<ClientAccess>();
  const [loading, setLoading] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const { token } = useCookies();

  const clientData = useQuery({
    queryKey: ['access-management', selectedClient?._id],
    queryFn: () => fetchAccessManagement({ clientId: selectedClient?._id }),
    enabled: !!selectedClient?._id,
    refetchOnWindowFocus: false,
    retry: 1,
  });

  useQuery({
    queryKey: ['permissions'],
    queryFn: () =>
      fetchPermissions({
        token,
        setNavigationData,
        setSelectedClient,
        setLoading,
        clientQuery: searchParams?.get('client'),
      }),
    refetchOnWindowFocus: false,
    retry: 1,
  });

  useQuery({
    queryKey: ['products', selectedClient?._id],
    queryFn: () =>
      fetchProductsDescription({
        setProductsDescription,
        setLoadingProducts,
        clientId: selectedClient?._id,
      }),
    retry: 1,
    refetchOnWindowFocus: false,
  });

  const contextValue = useMemo(
    () => ({
      navigationData,
      setNavigationData,
      productsDescription,
      setProductsDescription,
      selectedClient,
      setSelectedClient,
      setLoading,
      loading,
      setLoadingProducts,
      loadingProducts,
      clientData,
    }),
    [navigationData, productsDescription, selectedClient, clientData],
  );
  return <ReportsContext.Provider value={contextValue}>{children}</ReportsContext.Provider>;
};

export const useReportsContext = () => {
  const context = useContext(ReportsContext);
  if (context === undefined) {
    throw new Error('useReportsContext must be used within a ReportsProvider');
  }
  return context;
};
