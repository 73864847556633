import * as React from 'react';

const InformationIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M9 12.75c.213 0 .39-.072.535-.216A.723.723 0 009.75 12a.73.73 0 00-.216-.534A.724.724 0 009 11.25a.724.724 0 00-.534.216.73.73 0 00-.216.534c0 .212.072.39.216.535A.72.72 0 009 12.75zm-.75-3h1.5v-4.5h-1.5v4.5zM9 16.5a7.3 7.3 0 01-2.925-.591 7.585 7.585 0 01-2.381-1.603 7.558 7.558 0 01-1.603-2.381A7.32 7.32 0 011.5 9c0-1.037.196-2.012.591-2.925a7.591 7.591 0 011.603-2.381A7.563 7.563 0 016.075 2.09 7.306 7.306 0 019 1.5c1.037 0 2.011.197 2.925.591a7.563 7.563 0 012.381 1.603 7.602 7.602 0 011.604 2.381A7.26 7.26 0 0116.5 9a7.354 7.354 0 01-.591 2.925 7.524 7.524 0 01-1.603 2.381 7.619 7.619 0 01-2.381 1.604A7.266 7.266 0 019 16.5z"
      fill="#000"
    />
  </svg>
);

export default React.memo(InformationIcon);
