import { useQuery } from '@tanstack/react-query';
import { IProduct } from 'Models/IProduct';
import { NavigationType, Report } from 'Models/IReportsAccess';
import { fetchDatasets } from 'api/requests/deploybot';
import { useReportsContext } from 'context/ReportsContext';
import {
  addProductDescriptionToReports,
  filterReports,
  findAccessReportsBySection,
} from 'helpers/ReportsAccessHelper';
import { useEffect, useRef, useState } from 'react';

const usePageContainer = ({
  isMultiChannel,
  navigationSection,
}: {
  navigationSection: NavigationType;
  isMultiChannel: boolean;
}) => {
  const { selectedClient, productsDescription, clientData } = useReportsContext();
  const [accessReports, setAccessReports] = useState<Report[]>([]);
  const [hoveredReport, setHoveredReport] = useState<Report>();

  const observer = useRef(null);
  const reportRefs = useRef([]);
  const formattedProductsDescription = productsDescription.map((p) => ({
    ...p,
    disabled: true,
  }));

  const { data: dataSetsList } = useQuery({
    queryKey: ['datasets', selectedClient?.workspace],
    queryFn: () => fetchDatasets({ workspaceId: selectedClient?.workspace }),
    enabled: !!selectedClient?._id,
    refetchOnWindowFocus: false,
    retry: 1,
  });

  useEffect(() => {
    if (!selectedClient) {
      setAccessReports(formattedProductsDescription);
    }
    const reports = findAccessReportsBySection(
      selectedClient,
      navigationSection as NavigationType,
    );

    const disabledReports = formattedProductsDescription
      ?.map((el) => ({ ...el, productName: el.name }))
      ?.filter((el) => el.reportNavigationSection === navigationSection)
      .map((dr) => {
        const companyAccess = dataSetsList?.find(
          (el) => (el.productId as IProduct)?._id === dr._id && el.clientAccess,
        );
        return {
          ...dr,
          companyAccess: !!companyAccess,
        };
      })
      .sort((a, b) => a.productReportNavigationOrder - b.productReportNavigationOrder);

    const result = addProductDescriptionToReports(reports, disabledReports);

    if (reports?.length > 0) {
      const mergedReports = [...result, ...disabledReports];

      setAccessReports(filterReports(mergedReports));
    } else {
      setAccessReports(disabledReports);
    }
  }, [selectedClient, navigationSection, isMultiChannel, dataSetsList]);

  useEffect(() => {
    if (accessReports?.length > 0) {
      setHoveredReport({
        ...accessReports[0],
        productName: accessReports[0].productName,
      });
    }
  }, [accessReports]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.remove('fadeaway');
          } else {
            entry.target.classList.add('fadeaway');
          }
        });
      },
      { threshold: 0.99 },
    );

    reportRefs.current.forEach((ref) => {
      if (ref) {
        observer.current.observe(ref);
      }
    });

    return () => {
      if (observer.current) {
        reportRefs.current.forEach((ref) => {
          if (ref) {
            observer.current.unobserve(ref);
          }
        });
      }
    };
  }, [accessReports]);

  const onOpenReport = (report: Report) => {
    if (!report.report_url) {
      setHoveredReport(report);
    } else {
      window.open(report.report_url);
    }
  };
  const onOpenBrochure = (report: Report) => {
    if (!report.downloadBrochureLink) {
      setHoveredReport(report);
    } else {
      window.open(report.downloadBrochureLink);
    }
  };

  return {
    hoveredReport,
    accessReports: accessReports.filter((el) => !(el.excludeCrossSelling && !el.report_url)),
    setHoveredReport,
    onOpenReport,
    client: clientData?.data,
    reportRefs,
    onOpenBrochure,
  };
};

export default usePageContainer;
