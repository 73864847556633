import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { IAccessManagement } from 'Models/IClient';
import { IProduct } from 'Models/IProduct';
import {
  fetchDatasets,
  UpdateAccessManagementResponse,
  GrantAccessByEmailsToSelectedSecurityGroups,
} from 'api/requests/deploybot';
import { useReportsContext } from 'context/ReportsContext';
import { shortenAccessManagementNames } from 'helpers/shortenAccessManagementNames';
import { getPrimaryEmail } from 'helpers/utils';
import useCookies from 'hooks/useCookies';
import { useEffect, useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { ISelectorOption } from 'types/ISelectorOption';

const useAccessManagmentPage = () => {
  const { selectedClient, productsDescription, clientData } = useReportsContext();
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();

  const { email } = useCookies();

  const {
    data: dataSetsList,
    isLoading: isDatasetsLoading,
    isFetching: isDatasetsFetching,
  } = useQuery({
    queryKey: ['datasets', selectedClient?.workspace],
    queryFn: () => fetchDatasets({ workspaceId: selectedClient?.workspace }),
    enabled: !!selectedClient?._id,
    refetchOnWindowFocus: false,
    retry: 1,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!clientData?.data || !email) return;

    if (
      !clientData?.data?.accessManagment?.find(
        (el) => el.user.email === getPrimaryEmail(email),
      )?.isAdmin
    ) {
      navigate(`/portal?client=${searchParams.get('client')}`);
    }
  }, [clientData?.data, email, selectedClient]);

  const updateAccessesMutation = useMutation({
    mutationFn: async ({ values, clientId }: UpdateAccessManagementResponse) =>
      GrantAccessByEmailsToSelectedSecurityGroups({ values, clientId }),

    onSettled: (data, error) => {
      if (!error) {
        queryClient.setQueryData(['access-management', selectedClient?._id], data);
      }
    },
  });

  const onSubmitAccessManagement = async (values: {
    accessManagement?: IAccessManagement<ISelectorOption>[];
  }) => {
    if (!clientData?.data || !values.accessManagement) return;

    const formattedValues = values.accessManagement
      ?.map((el) => ({
        ...el,
        accessGroups: el.accessGroups.map((group) => ({
          productName: group.label,
          securityGroupId: group.value,
        })),
      }))
      .filter((el) => !el?.isRemoved);

    await updateAccessesMutation.mutateAsync({
      values: formattedValues,
      clientId: clientData.data?._id,
    });
  };

  const activeReports = useMemo(() => {
    if (!clientData?.data || !dataSetsList) return;

    const allDataSetsWithSecurityGroups = dataSetsList
      // check active products
      ?.filter((ds) => ds.clientAccess)
      .filter(
        (ds) => ds.securityGroupId && ds.securityGroupId.clientId === clientData?.data?._id,
      )
      .map((el) => {
        const addSecurityGroupsToClients = clientData?.data?.configs.map((conf) => {
          const matchedDataset = dataSetsList.find(
            (ds) =>
              ds.productId?._id === conf.product &&
              ds.securityGroupId?.clientId === clientData?.data?._id,
          );
          if (matchedDataset) {
            return { ...conf, securityGroup: matchedDataset.securityGroupId };
          }
          return conf;
        });

        const productDataset = addSecurityGroupsToClients.find(
          (conf) => conf.securityGroup?.datasetId === el._id,
        );
        return { ...el, active: productDataset?.active };
      })
      .map((fr) => {
        if (fr.productId) {
          return {
            value: fr.securityGroupId?._id as string,
            label: productsDescription.find((el) => el._id === (fr.productId as IProduct)?._id)
              ?.reportNavigationDisplayName,
          };
        }
        return {
          value: fr.securityGroupId?._id as string,
          label: shortenAccessManagementNames(
            fr.securityGroupId?.name as string,
            clientData?.data?.clientAbbreviation,
          ),
        };
      });

    return allDataSetsWithSecurityGroups;
  }, [dataSetsList, clientData?.data, productsDescription]);

  const accessManagement = useMemo(
    () => ({
      accessManagement:
        clientData?.data?.accessManagment?.map((el) => ({
          ...el,
          accessGroups: el.accessGroups.map((group) => ({
            label: shortenAccessManagementNames(
              productsDescription?.find((pd) => pd.name === group.productName)
                ?.reportNavigationDisplayName || group.productName,
              clientData?.data?.clientAbbreviation,
            ),
            value: group.securityGroupId,
          })),
        })) || [],
    }),
    [clientData?.data, dataSetsList, productsDescription, updateAccessesMutation.isLoading],
  );

  return {
    activeReports,
    onSubmitAccessManagement,
    isDatasetsLoading,
    isDatasetsFetching,
    clientData,
    updateAccessesMutation,
    accessManagement,
  };
};

export default useAccessManagmentPage;
