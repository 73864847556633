import PageTitle from 'components/PageTitle';
import PageContainer from 'containers/PageContainer';

const MediaAnalyticsPage = () => (
  <PageContainer
    navigationSection="Media"
    title={
      <PageTitle
        title="Amazon Media"
        subtitle="Detailed insights for optimal budget allocation."
      />
    }
  />
);

export default MediaAnalyticsPage;
