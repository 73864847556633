import * as React from 'react';
import { SVGProps } from 'react';

const TrashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <path
      fillRule="evenodd"
      d="M17.8 7.25c1.016 0 1.865.751 1.944 1.727l.006.148v1.125H24a.75.75 0 0 1 .102 1.493L24 11.75h-1.311l-.952 11.31a1.83 1.83 0 0 1-1.682 1.684l-.15.006h-7.808a1.83 1.83 0 0 1-1.816-1.536l-.017-.148L9.31 11.75H8a.75.75 0 0 1-.102-1.493L8 10.25h4.25V9.125c0-.997.801-1.796 1.799-1.87l.151-.005zm3.384 4.5H10.815l.944 11.198a.326.326 0 0 0 .193.272l.068.022.075.008h7.812a.332.332 0 0 0 .321-.237l.014-.073zM17.5 14a.75.75 0 0 1 .743.648l.007.102v5.5a.75.75 0 0 1-1.493.102l-.007-.102v-5.5a.75.75 0 0 1 .75-.75zm-3 0a.75.75 0 0 1 .743.648l.007.102v5.5a.75.75 0 0 1-1.493.102l-.007-.102v-5.5a.75.75 0 0 1 .75-.75zm3.3-5.25h-3.6c-.227 0-.401.137-.441.303l-.009.072v1.125h4.5V9.125c0-.147-.107-.284-.27-.344l-.086-.023z"
    />
  </svg>
);
export default TrashIcon;
