import { SVGProps } from 'react';

const Locked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    {...props}
  >
    <path d="M24 9.5c4.087 0 7.37 3.17 7.496 7.254L31.5 17v2.503c1.654.07 3 1.393 3 3.053v10.888c0 1.705-1.42 3.056-3.136 3.056H16.636c-1.715 0-3.136-1.35-3.136-3.056V22.556c0-1.705 1.42-3.056 3.136-3.056l-.136.003V17c0-4.202 3.33-7.5 7.5-7.5zm7.364 13H16.636c-.091 0-.136.042-.136.056v10.888c0 .014.045.056.136.056h14.728c.091 0 .136-.042.136-.056V22.556c0-.014-.045-.056-.136-.056zM24 24.75a2.25 2.25 0 0 1 1.5 3.927V30a1.5 1.5 0 0 1-2.993.144L22.5 30v-1.322A2.25 2.25 0 0 1 24 24.75zm0-12.25c-2.447 0-4.386 1.85-4.495 4.284L19.5 17v2.5h9V17c0-2.539-1.981-4.5-4.5-4.5z" />
  </svg>
);
export default Locked;
