import { SVGProps } from 'react';

const BespokeSolutions = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M35.8 11a3.2 3.2 0 0 1 3.2 3.2l.002 11.089a8.5 8.5 0 1 1-11.713 11.713L12.2 37A3.2 3.2 0 0 1 9 33.8V14.2a3.2 3.2 0 0 1 3.2-3.2zm-1.3 15-.233.004a6.5 6.5 0 0 0-6.255 6.891l-.007-.125c.004.097.01.193.019.289l-.012-.164c.005.093.013.185.022.276l-.01-.112c.01.128.025.255.043.382l-.033-.27c.011.109.025.216.042.323l-.009-.053c.012.079.025.158.04.237l-.031-.184c.015.103.034.205.054.307l-.024-.123c.042.226.095.45.16.669l.022.072-.022-.072c.026.09.055.179.085.267l-.063-.195c.03.096.061.19.095.284l-.032-.09c.039.113.08.224.125.333l-.093-.243c.036.1.075.2.116.299l-.023-.056c.036.09.075.179.115.267l-.092-.211A6.502 6.502 0 0 0 41 32.5a6.5 6.5 0 0 0-3.474-5.754l-.247-.124a6.466 6.466 0 0 0-.066-.03l.066.03a6.458 6.458 0 0 0-.27-.12l.204.09a6.462 6.462 0 0 0-.264-.115l.06.025a6.442 6.442 0 0 0-.306-.12l.246.095a6.453 6.453 0 0 0-.335-.125l.089.03a6.435 6.435 0 0 0-.284-.094l.195.064a6.449 6.449 0 0 0-.267-.086l.072.022a6.439 6.439 0 0 0-.342-.096l.27.074a6.45 6.45 0 0 0-.296-.08l.026.006a6.452 6.452 0 0 0-.266-.06l.24.054a6.461 6.461 0 0 0-.348-.075l.108.021a6.474 6.474 0 0 0-.31-.055l.202.034a6.481 6.481 0 0 0-.262-.043l.06.009a6.509 6.509 0 0 0-.33-.043l.27.034a6.514 6.514 0 0 0-.383-.044l.114.01a6.553 6.553 0 0 0-.276-.022l.162.012a6.562 6.562 0 0 0-.289-.019zm0 2.286a1 1 0 0 1 .993.883l.007.117V31.5h2.214a1 1 0 0 1 .117 1.993l-.117.007H35.5v2.214a1 1 0 0 1-1.993.117l-.007-.117V33.5h-2.214a1 1 0 0 1-.117-1.993l.117-.007H33.5v-2.214a1 1 0 0 1 1-1zm.255-4.282.09.003a8.419 8.419 0 0 1 .337.02l.023.002c.112.009.222.02.331.034.009 0 .017.002.026.003a8.126 8.126 0 0 1 .394.058c.357.062.706.146 1.046.25L37 14.2a1.2 1.2 0 0 0-1.077-1.194L35.8 13H12.2a1.2 1.2 0 0 0-1.2 1.2v19.6a1.2 1.2 0 0 0 1.2 1.2l14.174.002a8.44 8.44 0 0 1-.218-.875l-.016-.085A8.5 8.5 0 0 1 34.5 24zM15.477 16c.816 0 1.478.672 1.478 1.5s-.662 1.5-1.478 1.5S14 18.328 14 17.5s.661-1.5 1.477-1.5zM28 16.5a1 1 0 0 1 .117 1.993L28 18.5h-8a1 1 0 0 1-.117-1.993L20 16.5z"
    />
  </svg>
);
export default BespokeSolutions;
