import axios from 'axios';
import Cookies from 'js-cookie';

export const baseURL = process.env.REACT_APP_BASE_URL;
export const accessBaseURL = process.env.REACT_APP_ACCESS_BASE_URL;

export const clientApi = axios.create({
  baseURL,
  headers:
    process.env.NODE_ENV === 'development'
      ? {
          apikey: process.env.REACT_APP_ADMIN_API_KEY,
        }
      : {},
});

export const accessApi = axios.create({
  baseURL: accessBaseURL,
  headers: {
    'apiKey ': process.env.REACT_APP_ACCESS_API_KEY,
  },
});

export const deploybotApi = axios.create({
  baseURL: `${baseURL}deploybot?forward_url=${process.env.REACT_APP_DEPLOYBOT_API_URL}`,
  headers:
    process.env.NODE_ENV === 'development'
      ? {
          apiKey: process.env.REACT_APP_ADMIN_API_KEY,
          external_apikey: process.env.REACT_APP_EXTERNAL_API_TOKEN,
        }
      : { external_apikey: process.env.REACT_APP_EXTERNAL_API_TOKEN },
});

const removeCookies = () => {
  Cookies.remove('error_type');
  Cookies.remove('error_msg');
  Cookies.remove('token');
  Cookies.remove('uname');
  Cookies.remove('email');
};

clientApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const request = error.config;
    if (error.response?.status === 403 && !request._retry) {
      request._retry = true;

      if (typeof document !== 'undefined') {
        window.location.href = `${process.env.REACT_APP_BASE_URL}logout?token=${Cookies.get(
          'token',
        )}`;
        removeCookies();
      }

      return clientApi(request);
    }
    return Promise.reject(error);
  },
);

deploybotApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const request = error.config;
    if (error.response?.status === 403 && !request._retry) {
      request._retry = true;
      if (typeof document !== 'undefined') {
        window.location.href = `${process.env.REACT_APP_BASE_URL}logout?token=${Cookies.get(
          'token',
        )}`;
        removeCookies();
      }

      return deploybotApi(request);
    }
    return Promise.reject(error);
  },
);
