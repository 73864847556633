/* eslint-disable max-lines */
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { IAccessManagement } from 'Models/IClient';
import TrashIcon from 'assets/svg/TrashIcon';
import UserProfileIcon from 'assets/svg/UserProfileIcon';
import { Columns } from 'components/DataTable/Columns';
import FormCheckBox from 'components/Form/FormCheckBox';
import FormSelect from 'components/Form/FormSelect';
import { globalAppHexColors } from 'constants/globalAppHexColors';
import Fuse from 'fuse.js';
import { getPrimaryEmail } from 'helpers/utils';
import useCookies from 'hooks/useCookies';
import _, { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { ISelectorOption } from 'types/ISelectorOption';

const useAddMembersForm = ({
  defaultValues,
  activeReports,
}: {
  defaultValues: {
    accessManagement?: IAccessManagement<ISelectorOption>[];
  };
  activeReports?: ISelectorOption[];
}) => {
  const formMethods = useForm({ defaultValues });
  const {
    handleSubmit,
    setValue,
    control,
    reset,
    clearErrors,

    formState: { dirtyFields, isDirty },
  } = formMethods;
  const [selectedEmails, setSelectedEmails] = useState<ISelectorOption[]>([]);
  const [selectedReports, setSelectedReports] = useState<ISelectorOption[]>([]);
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);

  const { email } = useCookies();
  const [multiInputError, setMultiInputError] = useState<string[]>([]);

  const formValues = useWatch({ name: 'accessManagement', control });

  const onAddUser = () => {
    if (isDirty) {
      setIsConfirmationModal(true);
    } else {
      setSlideOverOpen(true);
    }
  };

  const onCloseModal = () => {
    setIsConfirmationModal(false);
  };

  const onAppendEmail = async (values: ISelectorOption[]) => {
    if (values.length === 0) {
      setMultiInputError(['Field is required']);
    }
    setValue('accessManagement', [], {
      shouldDirty: true,
    });
    const updatedAccessManagment: IAccessManagement<ISelectorOption>[] = [];
    await Promise.all(
      (formValues as IAccessManagement<ISelectorOption>[])?.map(
        (us: IAccessManagement<ISelectorOption>) => {
          const alreadyExistedUser = values.find((v) => v.value === us.user.id);
          if (alreadyExistedUser) {
            const updatedUser = {
              ...us,
              // Update the user with primary
              user: { id: alreadyExistedUser.value, email: alreadyExistedUser.label },
              emailChanged: alreadyExistedUser.label !== us.user.email ? us.user.email : '',
              isUpdated: true,
              isAdmin: us.isAdmin,
              accessGroups: _.unionBy(us.accessGroups, selectedReports, 'value'),
            };
            updatedAccessManagment.push(updatedUser);
          } else {
            updatedAccessManagment.push({
              ...us,
            });
          }
          return null;
        },
      ),
    );
    const newAccessManagment = await Promise.all(
      values
        .filter((el) => {
          if (updatedAccessManagment.find((ua) => ua.user.id === el.value)) {
            return false;
          }
          return true;
        })
        .map((el) => {
          const newUser = {
            user: { id: el.value, email: el.label },
            accessGroups: selectedReports,
            isNew: true,
            isAdmin: false,
            isUpdated: false,
          };
          return newUser;
        }),
    );

    (
      [
        ...newAccessManagment,
        ...updatedAccessManagment,
      ] as IAccessManagement<ISelectorOption>[]
    ).map((el, index) => {
      if (el.isNew || el.isUpdated) {
        setValue(`accessManagement.${index}` as const, el, { shouldDirty: true });
        return null;
      }
      setValue(`accessManagement.${index}` as const, el, { shouldDirty: false });
      return null;
    });

    setSelectedEmails([]);
    setSelectedReports([]);
    setSlideOverOpen(false);
  };

  const onRemoveEmail = (email_index: number) => {
    const selectedEmail = formValues[email_index];
    setValue(
      `accessManagement.${email_index}` as const,
      { ...selectedEmail, isRemoved: !selectedEmail.isRemoved },
      { shouldDirty: true, shouldValidate: true },
    );
    clearErrors(`accessManagement.${email_index}.accessGroups` as const);
  };

  // Search

  const [searchQuery, setSearchQuery] = useState('');
  const virtuoso = useRef(null);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const [searchIndex, setSearchIndex] = useState(0);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const clientsSearch = debounce((query: string) => {
    if (query.trim() === '') {
      setSearchIndex(0);
      virtuoso.current?.scrollToIndex({
        index: 0,
        align: 'start',
      });
      return null;
    }
    const fuse = new Fuse(formValues || [], {
      keys: ['user.email'],
      threshold: 0.25,
      location: 0,
      distance: 100,
      includeMatches: true,
      includeScore: true,
      useExtendedSearch: true,
    });
    const results = fuse.search(query);

    if (results.length !== 0) {
      setSearchIndex(results?.[0].refIndex);
      virtuoso.current?.scrollToIndex({
        index: results?.[0].refIndex,
        align: 'start',
        behavior: 'smooth',
      });
    } else {
      setSearchIndex(null);
    }
  }, 400);

  useEffect(() => {
    clientsSearch(searchQuery);
  }, [searchQuery]);

  // END Search

  const getUserStatusColor = (
    row: IAccessManagement<ISelectorOption>,
    dirtyField: boolean,
    index: number,
  ) => {
    if (row?.isRemoved) {
      return 'bg-burgundy_light';
    }
    if (row?.isNew) {
      return 'bg-secondary';
    }
    if (row?.isUpdated || dirtyField) {
      return 'bg-default_gray';
    }
    if (index === searchIndex && index !== 0) {
      return 'border-t border-black bg-gray-50';
    }
    return '';
  };

  const toggleSlideOver = (open: boolean) => {
    setSlideOverOpen(open);
  };

  const managementAccessColumns: Columns<IAccessManagement<ISelectorOption>>[] = [
    {
      title: 'Admin',
      omit: !defaultValues.accessManagement.find(
        (el) => el?.user?.email === getPrimaryEmail(email),
      )?.isAdmin,
      className: 'w-[5%]',
      center: true,
      cell: (row, index) => (
        <div>
          <FormCheckBox
            disabled={row?.isRemoved || getPrimaryEmail(email) === row?.user?.email}
            name={`accessManagement.${index}.isAdmin` as const}
          />
        </div>
      ),
    },
    {
      title: 'Email',

      cell: (row, _index) => (
        <div className="flex items-center ">
          <div className="me-1">
            <UserProfileIcon fill={globalAppHexColors.primary} />
          </div>
          <div className=" font-worksans_regular text-[16px] leading-[19px] break-all">
            {row?.user?.email}
          </div>
        </div>
      ),
    },
    {
      title: 'Module access',
      className: 'w-[50%]',
      cell: (_row, index) => (
        <div key={_row?.user?.id}>
          {activeReports?.length !== 0 && activeReports && activeReports.length !== 0 ? (
            <FormSelect
              fieldArrayKey="accessManagement"
              validation={{
                required: !_row?.isRemoved
                  ? 'Please add modules or remove the user before saving.'
                  : false,
              }}
              reverseIndicator
              menuPortalTarget={document.body}
              isDisabled={_row?.isRemoved}
              options={activeReports}
              withoutBorder
              isMulti
              menuPlacement="auto"
              isClearable={false}
              placeholder="Click here to edit module access"
              name={`accessManagement.${index}.accessGroups` as const}
            />
          ) : (
            <div className="font-worksans_regular text-[16px] leading-[19px]">
              No access groups exists
            </div>
          )}
        </div>
      ),
    },

    {
      title: 'Remove',
      className: 'w-[5%]',
      center: true,
      cell: (_row, index) => (
        <button
          type="button"
          disabled={email === _row?.user?.email}
          className="btn btn-danger text-white "
          onClick={() => onRemoveEmail(index)}
        >
          {_row?.isRemoved ? (
            <ArrowUturnLeftIcon className="text-primary h-5 w-5" />
          ) : (
            <TrashIcon fill={email === _row?.user?.email ? globalAppHexColors.gray : ''} />
          )}
        </button>
      ),
    },
  ];

  return {
    managementAccessColumns,
    toggleSlideOver,
    onAppendEmail,
    getUserStatusColor,
    multiInputError,
    selectedEmails,
    slideOverOpen,
    handleSubmit,
    reset,
    dirtyFields,
    isDirty,
    formMethods,
    selectedReports,
    setSelectedReports,
    setMultiInputError,
    setSelectedEmails,
    formValues,
    isConfirmationModal,
    onAddUser,
    onCloseModal,
    virtuoso,
    searchIndex,
    onSearch,
    searchQuery,
  };
};

export default useAddMembersForm;
