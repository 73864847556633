import { SVGProps } from 'react';

const MultiChannel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    {...props}
  >
    <path d="M35 4a4 4 0 0 1 3.995 3.8L39 8v15a4 4 0 0 1-3.8 3.995L35 27H25v3.5h6.5a4 4 0 0 1 3.995 3.8l.005.2v1.376a4.002 4.002 0 0 1-1 7.874 4 4 0 0 1-1-7.874V34.5a2 2 0 0 0-1.85-1.995l-.15-.005H25v3.376a4.002 4.002 0 0 1 2.995 3.674l.005.2a4 4 0 1 1-5-3.874V32.5h-6.5a2 2 0 0 0-1.995 1.85l-.005.15v1.376a4.002 4.002 0 0 1-1 7.874 4 4 0 0 1-1-7.874V34.5a4 4 0 0 1 3.8-3.995l.2-.005H23V27h-9a4 4 0 0 1-3.995-3.8L10 23V8a4 4 0 0 1 3.8-3.995L14 4zM13.5 37.75a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm10.5 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm10.5 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM35 6H14a2 2 0 0 0-1.995 1.85L12 8v15a2 2 0 0 0 1.85 1.995L14 25h21a2 2 0 0 0 1.995-1.85L37 23V8a2 2 0 0 0-2-2zm-2.136 9.496A1 1 0 0 1 32.6 16.8l-.097.065-6 3.5a1 1 0 0 1-.58.133l-.12-.016-4.689-.939-4.209 1.872a1 1 0 0 1-1.266-.404l-.054-.104a1 1 0 0 1 .404-1.266l.104-.054 4.5-2a1 1 0 0 1 .48-.083l.122.016 4.628.926 5.672-3.309a1 1 0 0 1 1.368.36zM16.477 9c.816 0 1.478.672 1.478 1.5s-.662 1.5-1.478 1.5S15 11.328 15 10.5 15.661 9 16.477 9zm12.023.5a1 1 0 0 1 .117 1.993l-.117.007h-7a1 1 0 0 1-.117-1.993L21.5 9.5z" />
  </svg>
);
export default MultiChannel;
