import React from 'react';
import { ITooltip, Tooltip } from 'react-tooltip';

interface MultiToolTipProps {
  title?: string;
  children?: React.ReactNode;
  id: string;
  className?: string;
  hoverComponent: React.ReactNode;
}

const MultiToolTip: React.FC<MultiToolTipProps & ITooltip> = ({
  id,
  children,
  title,
  hoverComponent,
  ...props
}) => (
  <div>
    <div data-tooltip-id={id}>{hoverComponent}</div>
    <div className="toolTipCardContainer">
      <Tooltip className="tooltipCard" id={id} opacity={1} {...props}>
        <div>
          <p>{title}</p>
          {children}
        </div>
      </Tooltip>
    </div>
  </div>
);
export default MultiToolTip;
