import * as React from 'react';
import { SVGProps } from 'react';

const Logout = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <path d="m19 5 .117.007A1 1 0 0 1 19 7H9l-.117.007A1 1 0 0 0 8 8v16l.007.117A1 1 0 0 0 9 25h10l.117.007A1 1 0 0 1 19 27H9l-.176-.005A3 3 0 0 1 6 24V8l.005-.176A3 3 0 0 1 9 5zm5.511 6.14.102.07.094.083 4 4c.036.035.068.073.097.112l.071.11.054.114.035.105.03.148L29 16l-.003.075-.017.126-.03.111-.044.111-.052.098-.074.104-.073.082-4 4a1 1 0 0 1-1.497-1.32l.083-.094L25.585 17H15a1 1 0 0 1-.117-1.993L15 15h10.585l-2.292-2.293a1 1 0 0 1-.083-1.32l.083-.094a1 1 0 0 1 1.218-.153z" />
  </svg>
);
export default Logout;
