/* eslint-disable @typescript-eslint/no-explicit-any */
import { Columns, DataTableProps } from './Columns';
import { TableVirtuoso } from 'react-virtuoso';
import DataTableRow from './DataTableRow';
import { forwardRef } from 'react';

const VirtualizedDataTable = forwardRef<HTMLDivElement, DataTableProps<any>>(
  ({ data, columns, rowStyle, className }: DataTableProps<any>, ref) =>
    data.length ? (
      <div className={`mt-8 w-full ${className}`}>
        <TableVirtuoso
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={ref}
          data={data}
          fixedHeaderContent={() => (
            <tr className="bg-white">
              {columns
                .filter((el) => !el.omit)
                .map((col) => (
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-primary"
                    key={`${col.title as string}`}
                  >
                    <div className="font-worksans_regular text-[16px] leading-[19px]">
                      {col.title}
                    </div>
                  </th>
                ))}
            </tr>
          )}
          itemContent={(index, row) => (
            <DataTableRow
              key={index.toString()}
              rowStyle={rowStyle?.(row, index)}
              item={row}
              itemIndex={index}
              columns={columns as Columns<Record<string | number, unknown>>[]}
            />
          )}
        />
      </div>
    ) : (
      <div className="text-gray-700 font-medium flex items-center h-[32rem] justify-center">
        The list is empty
      </div>
    ),
);

export default VirtualizedDataTable;
