import * as React from 'react';
import { useFormContext, Controller, RegisterOptions } from 'react-hook-form';
import { get } from 'lodash';
import Label from 'components/Form/Label';
import Select, { ReactSelectProps } from './ReactSelect';

interface IProps extends Omit<ReactSelectProps, 'errors' | 'onChange' | 'value'> {
  name: string;
  validation?: RegisterOptions;
  fieldArrayKey?: string;
  index?: number;
  label?: string;
}

const FormSelect: React.FC<IProps> = ({
  name,
  validation,
  options,
  isMulti,
  label,
  ...selectProps
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      {label && <Label {...{ label, name }} />}

      <Controller
        name={name}
        rules={validation}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            {...selectProps}
            id={name}
            options={options}
            isMulti={isMulti}
            onChange={onChange}
            value={value}
            error={get(errors, name)}
          />
        )}
      />
    </>
  );
};

export default FormSelect;
