import { WrenchScrewdriverIcon } from '@heroicons/react/20/solid';
import React from 'react';

const MaintenancePage: React.FC = () => (
  <main className="grid h-[100vh] place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div className="flex flex-col justify-center items-center">
      <WrenchScrewdriverIcon className="w-20 h-20 text-primary" />
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        Under Maintenance
      </h1>
      <p className="mt-6 text-base leading-7 text-gray-600">
        Sorry, the website is currently under maintenance. Please check back later.
      </p>
    </div>
  </main>
);

export default MaintenancePage;
