import * as React from 'react';
import { SVGProps } from 'react';

const ArrowCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="m27.947 15.336.114.103 7.52 7.52.062.07-.082-.09c.041.042.08.085.115.13l.088.122.071.124a1.495 1.495 0 0 1 0 1.37l-.072.124-.034.051-.086.112-.082.089-7.5 7.5a1.5 1.5 0 0 1-2.225-2.008l.103-.114 4.939-4.939H13.5a1.5 1.5 0 0 1-.144-2.993l.144-.007h17.378l-4.939-4.94a1.5 1.5 0 0 1-.103-2.007l.103-.114a1.5 1.5 0 0 1 2.008-.103z"
    />
  </svg>
);
export default ArrowCard;
