import Button from 'components/Button';
import Modal from 'components/Modal';
import React, { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

interface BlockerProps {
  reason: boolean;
}

const Blocker: React.FC<BlockerProps> = ({ reason }) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      reason && currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    if (reason) {
      const handleTabClose = (event) => {
        event.preventDefault();
        return (event.returnValue = 'Are you sure you want to exit?');
      };

      window.addEventListener('beforeunload', handleTabClose);

      return () => {
        window.removeEventListener('beforeunload', handleTabClose);
      };
    }
  }, [reason]);

  return (
    <div>
      {blocker.state === 'blocked' ? (
        <Modal
          title="Do you really want to leave without saving?"
          containerStyleOrSize="lg"
          closeModal={() => blocker.reset()}
          isOpen={blocker.state === 'blocked'}
          withCloseIcon={false}
        >
          <div className="flex justify-end mt-4">
            <div className="flex justify-between ">
              <div className="mr-2">
                <Button
                  onClick={() => blocker.proceed()}
                  type="submit"
                  btnType="light"
                  textStyles="text-black"
                  btnSize="md"
                  main="Leave"
                />
              </div>

              <Button
                onClick={() => blocker.reset()}
                type="submit"
                btnType="primary"
                btnSize="md"
                main="Go back"
              />
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default Blocker;
