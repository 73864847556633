import * as React from 'react';
import { SVGProps } from 'react';

const Arrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="m18.613 10.21.094.083 5 5c.036.035.068.073.097.112l.071.11.054.114.035.105.03.148L24 16l-.003.075-.017.126-.03.111-.044.111-.052.098-.074.104-.073.082-5 5a1 1 0 0 1-1.497-1.32l.083-.094L20.585 17H9a1 1 0 0 1-.117-1.993L9 15h11.585l-3.292-3.293a1 1 0 0 1-.083-1.32l.083-.094a1 1 0 0 1 1.218-.153z"
    />
  </svg>
);
export default Arrow;
