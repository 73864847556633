import * as React from 'react';
import { SVGProps } from 'react';

const Settings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="none"
      strokeWidth={2}
      d="M17.317 4c.776.01 1.463.535 1.69 1.302l.57 1.968c.039.151.147.293.294.363.432.212.835.454 1.228.727.138.09.305.121.462.08l1.945-.474a1.758 1.758 0 0 1 1.946.858l1.316 2.332a1.884 1.884 0 0 1-.246 2.16l-1.375 1.493a.626.626 0 0 0-.157.454 8.983 8.983 0 0 1 0 1.454c-.01.171.04.333.157.454l1.375 1.504c.531.585.63 1.463.246 2.16l-1.316 2.34a1.764 1.764 0 0 1-1.936.859l-1.945-.475a.577.577 0 0 0-.462.081 9.426 9.426 0 0 1-1.228.727.603.603 0 0 0-.295.363l-.57 1.968c-.226.777-.913 1.302-1.69 1.302h-2.633a1.797 1.797 0 0 1-1.69-1.302l-.57-1.968a.613.613 0 0 0-.304-.363 9.423 9.423 0 0 1-1.228-.727.577.577 0 0 0-.462-.08l-1.945.474a1.758 1.758 0 0 1-1.946-.858l-1.316-2.342a1.884 1.884 0 0 1 .246-2.16l1.375-1.493a.626.626 0 0 0 .157-.454 8.983 8.983 0 0 1 0-1.454.587.587 0 0 0-.157-.454l-1.375-1.494a1.884 1.884 0 0 1-.246-2.16l1.316-2.34a1.764 1.764 0 0 1 1.936-.859l1.945.475c.158.04.325.01.462-.081a9.426 9.426 0 0 1 1.228-.727.586.586 0 0 0 .305-.363l.57-1.968C13.22 4.525 13.907 4 14.684 4zM16 12c-2.146 0-3.894 1.795-3.894 4s1.748 4 3.894 4c2.147-.012 3.883-1.795 3.894-4 0-2.205-1.747-4-3.894-4z"
    />
  </svg>
);
export default Settings;
