/* eslint-disable react/jsx-one-expression-per-line */
import ClickableDiv from 'components/ ClickableDiv';
import ListViewCard from 'components/ListViewCard';
import React from 'react';

import usePageContainer from './usePageContainer';
import { NavigationType } from 'Models/IReportsAccess';
import AccessRequiredCard from 'components/ProductSectionsCTAs/AccessRequiredCard';
import CTAReportAccess from 'components/ProductSectionsCTAs/CTAReportAccess';
import HTMLDisplay from 'components/HTMLDisplay';
import CTASales from 'components/ProductSectionsCTAs/CTASales';

interface PageContainerProps {
  navigationSection: NavigationType;
  title: React.ReactNode;
  isMultiChannel?: boolean;
}

const PageContainer = ({
  navigationSection,
  title,
  isMultiChannel = false,
}: PageContainerProps) => {
  const {
    accessReports,
    setHoveredReport,
    hoveredReport,
    onOpenReport,
    client,
    reportRefs,
    onOpenBrochure,
  } = usePageContainer({
    isMultiChannel,
    navigationSection,
  });

  return (
    <div className="h-[calc(100vh-13vh)] flex flex-row">
      <div className="w-[calc(50%-3vw)] mr-[1vw] md:pr-0 flex flex-col h-full ">
        <div className="w-full">
          <div className="mb-10 xl:mb-12">{title}</div>
          {accessReports?.length > 0 ? (
            <div className="flex flex-col gap-8 xl:gap-10 flex-grow overflow-y-auto">
              <div className="flex flex-col h-[65vh] overflow-y-auto  scrollbar-thin scrollbar-thumb-black scrollbar-track-gray-200  border-default_gray relative">
                {accessReports.map((el, index) => (
                  <ClickableDiv
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${el.reportNavigationDisplayName}-${index}`}
                    onClick={() => onOpenReport(el)}
                    className="cursor-pointer"
                    ref={(ref) => {
                      if (index > 4) {
                        reportRefs.current[index] = ref;
                      }
                    }}
                  >
                    <ListViewCard
                      defaultReport={accessReports[0]}
                      report={el}
                      hoveredReport={hoveredReport}
                      setHoveredReport={setHoveredReport}
                    />
                  </ClickableDiv>
                ))}
              </div>
            </div>
          ) : (
            <p className="font-worksans_medium text-[18px] leading-6 text-left text-default_cool_gray">
              No reports available.
            </p>
          )}
        </div>
      </div>

      <div className="bg-light_gray w-[calc(50%+6vw)]">
        {accessReports?.length > 0 && (
          <div className="mx-[6vw] xl:mx-[6vw] flex justify-center h-full">
            {hoveredReport?.report_url ? (
              <CTAReportAccess report={hoveredReport} />
            ) : (
              <div className="h-full w-full">
                {!hoveredReport?.companyAccess ? (
                  <CTASales
                    onOpenBrochure={() => onOpenBrochure(hoveredReport)}
                    report={hoveredReport}
                  />
                ) : (
                  <div className="h-full w-full">
                    {hoveredReport && (
                      <>
                        <HTMLDisplay html={hoveredReport?.reportNavigationDescription} />
                        <AccessRequiredCard
                          adminsList={client?.accessManagment

                            ?.filter((el) => el.isAdmin)
                            ?.map((el) => el.user.email)
                            .slice(0, 3)}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PageContainer;
