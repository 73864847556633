import Spinner from 'components/Spinner';

import useAccessManagmentPage from './useAccessManagmentPage';
import AddMembersForm from 'containers/AddMembersForm';

const AccessManagmentPage = () => {
  const {
    activeReports,
    isDatasetsLoading,
    clientData,
    onSubmitAccessManagement,
    accessManagement,
    updateAccessesMutation,
  } = useAccessManagmentPage();

  return (
    <div className="md:container pr-[calc(6vw)] ">
      {updateAccessesMutation.isLoading || clientData.isLoading || isDatasetsLoading ? (
        <Spinner />
      ) : (
        <AddMembersForm
          title="Access Management"
          defaultValues={accessManagement}
          activeReports={activeReports}
          onSubmit={onSubmitAccessManagement}
          loadingSubmit={updateAccessesMutation.isLoading}
          isFetching={clientData.isFetching}
        />
      )}
    </div>
  );
};

export default AccessManagmentPage;
