import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment } from 'react';

interface SlideOverProps {
  children?: React.ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  subTitle?: string;
  size?: 'sm' | 'md' | 'lg';
  title?: string;
}

const SlideOver = ({
  children,
  isOpen,
  setIsOpen,
  size = 'md',
  title,
  subTitle,
}: SlideOverProps) => {
  const styles = React.useMemo(() => {
    let sizeStyle = '';
    switch (size) {
      case 'sm':
        sizeStyle = 'max-w-md';
        break;
      case 'md':
        sizeStyle = 'max-w-lg';
        break;
      case 'lg':
        sizeStyle = 'max-w-2xl ';
        break;
      default:
        sizeStyle = 'max-w-lg';
        break;
    }
    return {
      sizeStyle,
    };
  }, [size]);
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setIsOpen}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-200 sm:duration-200"
                enterFrom="translate-x-full opacity-0"
                enterTo="translate-x-0 opacity-100"
                leave="transform transition ease-in-out duration-200 sm:duration-200"
                leaveFrom="translate-x-0 opacity-100"
                leaveTo="translate-x-full opacity-0"
              >
                <Dialog.Panel className={`pointer-events-auto w-screen ${styles.sizeStyle}`}>
                  <div className="flex h-full flex-col overflow-y-auto bg-white shadow-xl">
                    {title && (
                      <div className="bg-gray-100 px-4 py-6 sm:px-6 font-worksans_medium">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              {title}
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">{subTitle}</p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-gray-400 hover:text-gray-500"
                              onClick={() => setIsOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="relative mt-6 flex-1 px-4 ">{children}</div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SlideOver;
