import React from 'react';

const HotspotBookMeeting = ({ hupSpotLink }: { hupSpotLink?: string }) => (
  <div>
    {hupSpotLink ? (
      <iframe
        title="hubspot"
        className="w-[60vw] h-[84vh]  xl:h-[54vh] xl:w-[34.2vw]"
        src={hupSpotLink}
      />
    ) : (
      <h5>Can&apos;t book a meeting at the moment</h5>
    )}
  </div>
);

export default HotspotBookMeeting;
