import { SVGProps } from 'react';

const MultichannelCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    viewBox="0 0 64 64"
    {...props}
  >
    <path d="M47 5.5a4.5 4.5 0 0 1 4.495 4.288L51.5 10v22a4.5 4.5 0 0 1-4.288 4.495L47 36.5H33.5v4H43a4.5 4.5 0 0 1 4.495 4.288L47.5 45v2.707a5.5 5.5 0 1 1-3 0V45a1.5 1.5 0 0 0-1.356-1.493L43 43.5h-9.501v4.207a5.503 5.503 0 0 1 3.997 5.072L37.5 53a5.5 5.5 0 1 1-7-5.293l-.001-4.207H21a1.5 1.5 0 0 0-1.493 1.356L19.5 45v2.707a5.5 5.5 0 1 1-3 0V45a4.5 4.5 0 0 1 4.288-4.495L21 40.5h9.5v-4H17a4.5 4.5 0 0 1-4.495-4.288L12.5 32V10a4.5 4.5 0 0 1 4.288-4.495L17 5.5zm-29 45a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zm14 0a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zm14 0a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zm1-42H17a1.5 1.5 0 0 0-1.493 1.356L15.5 10v22a1.5 1.5 0 0 0 1.356 1.493L17 33.5h30a1.5 1.5 0 0 0 1.493-1.356L48.5 32V10A1.5 1.5 0 0 0 47 8.5zm-3.504 12.224a1.482 1.482 0 0 1-.372 1.934l-.144.097-7.777 4.63c-.233.138-.498.21-.766.208l-.202-.015-6.13-.877-6.143 2.108a1.482 1.482 0 0 1-1.816-.76l-.066-.161a1.482 1.482 0 0 1 .761-1.817l.16-.065 6.482-2.222c.166-.057.34-.084.515-.08l.175.015 5.962.851 7.33-4.362a1.481 1.481 0 0 1 2.03.516zM21.13 12a2.222 2.222 0 1 1 0 4.444 2.222 2.222 0 0 1 0-4.444zm14.814.74a1.481 1.481 0 0 1 .173 2.954l-.173.01h-8.888a1.481 1.481 0 0 1-.173-2.953l.173-.01z" />
  </svg>
);
export default MultichannelCard;
