import React from 'react';

const HTMLDisplay: React.FC<{ html: string }> = ({ html }) => (
  <div
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: html,
    }}
    className="text-primary font-worksans_medium overflow-y-auto scrollbar-thin scrollbar-thumb-black scrollbar-track-gray-200 "
  />
);
export default HTMLDisplay;
