import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import Button from 'components/Button';
import HTMLDisplay from 'components/HTMLDisplay';
import Modal from 'components/Modal';
import HotspotBookMeeting from 'containers/HotspotBookMeeting';
import { Report } from 'Models/IReportsAccess';
import React, { useState } from 'react';

interface CTASalesProps {
  report: Report;
  onOpenBrochure: () => void;
}
const CTASales: React.FC<CTASalesProps> = ({ report, onOpenBrochure }) => {
  const [isBookMeetingModalOpen, setIsBookMeetingModalOpen] = useState(false);

  const onOpenBookMeeting = () => {
    setIsBookMeetingModalOpen(true);
  };

  const onCloseBookMeeting = () => {
    setIsBookMeetingModalOpen(false);
  };

  const buttonsCss =
    '2xl:text-[24px]  2xl:leading-[20px] xl:leading-[20px]  xl:text-[21px]  lg:text-[16px] lg:leading-[20px] md:text-[14px] md:leading-[20px] text-[14px] leading-[20px]';

  return (
    <div className="h-full">
      <HTMLDisplay html={report?.marketingDescription} />

      <div className="w-100 mt-10 flex flex-row justify-center ">
        <Button
          main={
            <div className={`flex items-center font-worksans_semibold  ${buttonsCss}`}>
              Start Now!
              {/* <ArrowTopRightOnSquareIcon className="h-6 w-6 ms-1" /> */}
            </div>
          }
          btnType="primary"
          onClick={onOpenBookMeeting}
          containerStyles={`${
            report?.downloadBrochure ? 'w-[49%] me-2' : 'w-full xl:w-[75%]'
          }`}
        />
        {report?.downloadBrochure && (
          <Button
            onClick={onOpenBrochure}
            main={
              <div className={`flex items-center font-worksans_semibold  ${buttonsCss}`}>
                Discover more!
                <ArrowTopRightOnSquareIcon className="h-5 w-5 ms-2" />
                {/* <ArrowTopRightOnSquareIcon className="h-6 w-6 ms-1" /> */}
              </div>
            }
            btnType="light"
            containerStyles="w-[49%]"
            textStyles="text-black"
          />
        )}
      </div>
      <Modal
        containerStyleOrSize="w-[63vw] xl:h-[58vh] xl:w-[36vw] p-0"
        closeModal={onCloseBookMeeting}
        isOpen={isBookMeetingModalOpen}
        withCloseIcon={false}
      >
        <HotspotBookMeeting hupSpotLink={report?.hupSpotLink} />
      </Modal>
    </div>
  );
};
export default CTASales;
