interface LabelProps {
  label: string;
  name: string;
}
const Label = ({ label, name }: LabelProps) => (
  <div className="mb-2">
    <label className="block text-sm  text-gray-700" htmlFor={name}>
      {label}
    </label>
  </div>
);
export default Label;
