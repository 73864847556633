import Spinner from 'components/Spinner';
import { useReportsContext } from 'context/ReportsContext';
import { Outlet } from 'react-router-dom';

const PortalPage = () => {
  const { loading, loadingProducts } = useReportsContext();

  return (
    <div>
      <div>
        {loading && loadingProducts ? (
          <div className="relative h-[80vh] mr-20">
            <Spinner />
          </div>
        ) : (
          <Outlet />
        )}
      </div>
    </div>
  );
};

export default PortalPage;
