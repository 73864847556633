/**
 * Dashboard component displays a list of navigation cards for different product lines.
 * @returns A React component that displays a list of navigation cards.
 */

import NavigationCard from 'components/NavigationCard';
import { useReportsContext } from 'context/ReportsContext';
import { hasAccess } from 'helpers/ReportsAccessHelper';
import { convertOctalToUnicode } from 'helpers/convertOctalToUnicode';
import Cookies from 'js-cookie';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';

const Dashboard = () => {
  const [ftbAccess, setFtbAccess] = React.useState(false);
  const { selectedClient, navigationData } = useReportsContext();

  const userName = convertOctalToUnicode(Cookies.get('uname'));

  React.useEffect(() => {
    if (navigationData?.auth0_roles?.find((el) => el.name.startsWith('ftb'))) {
      setFtbAccess(true);
    }
  }, [navigationData]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, _setSearchParams] = useSearchParams();
  const query = new URLSearchParams({ client: searchParams.get('client') });

  const navigation = React.useMemo(
    () => [
      {
        title: 'Amazon Retail',
        href: '/portal/retail',
        disabled: !hasAccess(selectedClient, 'Retail'),
        section: 'Retail',
      },
      {
        title: 'Amazon Media',
        href: '/portal/media',
        disabled: !hasAccess(selectedClient, 'Media'),
        section: 'Media',
      },
      {
        title: 'Amazon Digital Shelf',
        href: '/portal/digital-shelf',
        disabled: !hasAccess(selectedClient, 'Digital Shelf'),
        section: 'Digital Shelf',
      },
      {
        title: 'Bespoke Solutions',
        href: '/portal/bespoke-solutions',
        disabled: !hasAccess(selectedClient, 'Custom'),
        section: 'Custom',
      },
      {
        title: 'Multi-Channel',
        href: '/portal/multi-channel',
        disabled: !hasAccess(selectedClient, 'Multi-Channel'),
      },
    ],
    [selectedClient],
  );
  const { enabledNavigation, disabledNavigation } = React.useMemo(() => {
    const enabled = navigation.filter((el) => !el.disabled);
    const disabled = navigation.filter((el) => el.disabled);

    if (ftbAccess) {
      enabled.push({
        title: 'Amazon Seller Operations',
        href: process.env.REACT_APP_FORTRESS_BRAND_LINK as string,
        section: 'General Reports',
        disabled: false,
      });
    }

    return { enabledNavigation: enabled, disabledNavigation: disabled };
  }, [navigation, ftbAccess]);

  return (
    <div className="flex flex-col">
      <div className="mb-[40px] xl:mb-[64px] 2xl:mb-[64px]">
        <div>
          <span
            className={`${'font-sharpsans_bold  text-[32px] md:text-[40px] lg:text-[48px] xl:text-[48px] md:leading-[50px] xl:leading-[60px] lg:leading-[60px] leading-[40px]'} text-primary font-sharpsans_bold  text-left`}
          >
            {`Welcome back, ${userName !== 'undefined undefined' ? userName : ''}`}
          </span>
          <p className="font-worksans_regular text-[18px] md:text-[21px] 2xl:text-[21px] xl:leading-[24px] 2xl:leading-[24px] leading-[21px] text-left text-default_dark_gray mt-2">
            Make your selection from one of our product lines
          </p>
        </div>
      </div>
      {!!enabledNavigation.length && !!disabledNavigation.length && (
        <div>
          <div className="md:text-[18px]  md:leading-[21px]  text-[16px] leading-[19px] mb-[15px] font-worksans_medium">
            YOUR PRODUCTS
          </div>
          <div className="flex flex-wrap gap-[1.5vw] xl:max-w-[92%]  2xl:max-w-[92%]  lg:max-w-[92%]  md:max-w-[92%]  mb-[48px]">
            {enabledNavigation.map((navItem) => (
              <Link to={`${navItem.href}?client=${query.get('client')}`} key={navItem.title}>
                <NavigationCard
                  reportName={navItem.title}
                  disabled={navItem.disabled}
                  section={navItem.section}
                />
              </Link>
            ))}
          </div>
          <div className="md:text-[18px]  md:leading-[21px]  text-[16px] leading-[19px] mb-[15px] font-worksans_medium">
            DISCOVER MORE
          </div>
          <div className="flex flex-wrap gap-[1.5vw] xl:max-w-[80%]  2xl:max-w-[80%]  lg:max-w-[80%]  md:max-w-[90%]">
            {disabledNavigation.map((navItem) => (
              <Link to={`${navItem.href}?client=${query.get('client')}`} key={navItem.title}>
                <NavigationCard
                  reportName={navItem.title}
                  disabled={navItem.disabled}
                  section={navItem.section}
                />
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
