import React from 'react';
import { useFormContext } from 'react-hook-form';
import Label from 'components/Form/Label';

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface FormCheckBoxProps extends InputProps {
  label?: string;
  name: string;
  subLabel?: string;
}

const FormCheckBox = ({ name, label, subLabel, ...props }: FormCheckBoxProps) => {
  const { register } = useFormContext();
  return (
    <div className="relative flex gap-x-3">
      <div className="flex h-6 items-center">
        <input
          id="comments"
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 accent-primary bg-gray-700 text-primary"
          {...register(name)}
          name={name}
          {...props}
        />
      </div>
      <div className="text-sm leading-6">
        {label && <Label {...{ label, name }} />}
        {subLabel && <p className="text-gray-500">{subLabel}</p>}
      </div>
    </div>
  );
};

export default FormCheckBox;
