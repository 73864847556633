import { Report } from 'Models/IReportsAccess';
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import useListViewCard from './useListViewCard';

interface ListViewCardProps {
  hoveredReport: Report;
  defaultReport: Report;
  report: Report;
  setHoveredReport: React.Dispatch<React.SetStateAction<Report>>;
}

const ListViewCard = forwardRef<HTMLDivElement, ListViewCardProps>(
  ({ hoveredReport, defaultReport, setHoveredReport, report }, ref) => {
    const { backgroundColor, handleMouseEnter, handleMouseLeave, rightIcon } = useListViewCard(
      {
        defaultReport,
        hoveredReport,
        setHoveredReport,
        report,
      },
    );

    return (
      <div
        ref={ref}
        className={`${backgroundColor} h-[72px] xl:h-[80px] p-6 flex justify-between items-center border-b-2 border-default_gray`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className={clsx(
            report.disabled && !report.companyAccess && 'text-default_dark_gray',
            'text-black text-[21px] tracking-[0.3px] leading-[26.25px] xl:text-[24px] xl:leading-[30px] 2xl:text-[24px] 2xl:leading-[35px] text-left font-sharpsans_bold font-medium',
          )}
        >
          {report.reportNavigationDisplayName}
        </div>
        {rightIcon}
      </div>
    );
  },
);

export default ListViewCard;
