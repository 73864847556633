import * as React from 'react';
import { SVGProps } from 'react';

const MediaAnalytics = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    {...props}
  >
    <path d="M30.5 6a9.5 9.5 0 0 1 6.249 16.656c-.061.068-.132.13-.212.18A9.465 9.465 0 0 1 30.5 25a9.459 9.459 0 0 1-5.828-1.997L18 23c-4.383 0-4.571 6.59-.425 6.981l.21.014L18 30h13a1 1 0 0 1 .993.883L32 31v7a1 1 0 0 1-.883.993L31 39H18c-7.084 0-13-5.628-13-12.5 0-6.654 5.553-12.16 12.378-12.485l.31-.011L18 14l3.118-.001C21.838 9.466 25.764 6 30.5 6zm-9.487 10h-3l-.288.003C11.835 16.147 7 20.853 7 26.5c0 5.652 4.84 10.346 10.71 10.496L18 37h6v-5h-6.02l-.257-.006c-6.905-.29-6.8-10.75.036-10.99L18 21h4.753l-.024-.033-.078-.114a9.521 9.521 0 0 1-.07-.102l.148.216a9.488 9.488 0 0 1-1.225-2.405l-.036-.11a9.268 9.268 0 0 1-.239-.868l-.023-.11a9.443 9.443 0 0 1-.193-1.475zM30 32h-4v5h4zm9.298-1.48a1 1 0 0 1 1.202.98v2.614l3.184-1.063.113-.03a1 1 0 0 1 .52 1.928l-4.5 1.5-.115.03A1 1 0 0 1 38.5 35.5v-2.612l-3.184 1.06-.113.031a1 1 0 0 1-.52-1.928l4.5-1.5zM30.491 18c-2.04.001-3.823 1.485-4.383 3.581C27.343 22.474 28.86 23 30.5 23s3.157-.526 4.391-1.42c-.556-2.09-2.33-3.57-4.371-3.58zM30.5 8a7.5 7.5 0 0 0-7.489 7.09l.002-.033A7.623 7.623 0 0 0 23 15.5l.005.25v.041a7.58 7.58 0 0 0 .018.298l-.012-.177c.005.095.012.19.021.285l-.01-.108c.01.112.02.223.034.334l-.024-.226c.009.094.02.188.031.282l-.007-.056c.01.087.023.173.037.259l-.03-.203c.014.104.03.208.048.311l-.018-.108c.016.104.035.208.056.311l-.038-.203c.018.108.04.215.063.321l-.025-.118c.016.08.033.159.052.237l-.027-.119c.024.11.05.218.079.326l-.052-.207c.022.096.047.192.073.287l-.021-.08c.026.099.054.197.084.294l-.063-.214c.024.085.05.17.076.253l-.013-.04c.028.09.057.177.088.265l-.075-.225c.038.12.08.24.123.358l-.048-.133c.028.079.057.157.087.235l-.039-.102a7.486 7.486 0 0 0 1.06 1.916c.63-1.444 1.71-2.614 3.043-3.317a4 4 0 1 1 5.85.002c1.332.7 2.412 1.87 3.042 3.313A7.5 7.5 0 0 0 30.5 8zm0 4a2 2 0 0 0-.15 3.995l.143.005h.023a2 2 0 0 0-.016-4z" />
  </svg>
);
export default MediaAnalytics;
