export const globalAppHexColors = {
  primary: '#000000',
  secondary: '#E3FF00',
  neon_charts: '#DCF700',
  dark_neon: '#A7BD00',
  gray: '#E9E9EA',
  default_gray: '#939598',
  light_burgundy: '#EACBBB',
  semi_light_burgundy: '#E68585',
  burgundy: '#84344E',
  light_navy: '#C2D0F2',
  semi_light_navy: '#7797E6',
  navy: '#133BA1',
};
