import * as React from 'react';
import { SVGProps } from 'react';

const RetailAnalytics = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    {...props}
  >
    <path d="M18.5 32a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm12 0a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm-12 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM11.556 8c1.17 0 1.775.626 2.049 1.729l.056.257L14.23 13h20.8c1.257.036 2.07.781 2.016 1.964l-.018.205-.099.712-.856 5.494-1.097 6.848c-.242 1.059-.772 1.703-1.79 1.771L33 30H17c-1.14 0-1.719-.656-1.983-1.818l-3.361-18.036-.02-.124c-.004-.022-.009-.027-.02-.027l-.06.005H6a1 1 0 0 1-.117-1.993L6 8zM35 15H14.602l2.373 12.777.025.131.006.072-.004.02h15.989v-.024l.007-.064.015-.07 1.819-11.468.2-1.372zm-13.775 7.25c.29 0 .525.235.525.525v2.45c0 .29-.235.525-.525.525h-1.45a.525.525 0 0 1-.525-.525v-2.45c0-.29.235-.525.525-.525zm4.5-2c.29 0 .525.235.525.525v4.45c0 .29-.235.525-.525.525h-1.45a.525.525 0 0 1-.525-.525v-4.45c0-.29.235-.525.525-.525zm4.5-2c.29 0 .525.235.525.525v6.45c0 .29-.235.525-.525.525h-1.45a.525.525 0 0 1-.525-.525v-6.45c0-.29.235-.525.525-.525z" />
  </svg>
);
export default RetailAnalytics;
