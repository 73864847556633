import PageTitle from 'components/PageTitle';
import PageContainer from 'containers/PageContainer';

const MultiChannelPage = () => (
  <PageContainer
    navigationSection="Multi-Channel"
    isMultiChannel
    title={
      <PageTitle
        title="Multi-Channel"
        subtitle="Grow on Multichannel with smarter data insights."
      />
    }
  />
);

export default MultiChannelPage;
