import { deploybotApi } from 'api';
import { isAxiosError } from 'axios';
import { showToast } from 'helpers/ToastHelper';
import { IAccessManagement, IClient } from 'Models/IClient';
import { IDataset } from 'Models/IDatasets';
import { Report } from 'Models/IReportsAccess';

export const fetchAccessManagement = async ({ clientId }: { clientId: string }) => {
  try {
    const { data } = await deploybotApi.get<IClient | undefined>(`clients/${clientId}`, {
      params: { selected_params: '_id configs accessManagment clientAbbreviation' },
    });
    return data;
  } catch (error) {
    if (error.response || error.message) {
      showToast('error', isAxiosError(error) ? error.response?.data?.msg : error?.message);
    }
    return null;
  }
};
export const fetchDatasets = async ({ workspaceId }: { workspaceId: string }) => {
  try {
    const { data } = await deploybotApi.get<IDataset[] | undefined>(
      `/powerbi/datasets/${workspaceId}`,
    );
    return data;
  } catch (error) {
    showToast('error', isAxiosError(error) ? error.response?.data?.msg : error?.message);
    return [];
  }
};
export const getUserByEmail = async (value: string) => {
  const response = await deploybotApi.post('/graph/users', { email: value });
  return response;
};
export const inviteExternalUser = async (values: { emails: string[] }) => {
  const response = await deploybotApi.post('/graph/invite-external-users', values);
  return response;
};

export interface UpdateAccessManagementResponse {
  values: IAccessManagement<{
    productName: string;
    securityGroupId: string;
  }>[];
  clientId: string;
}
export const GrantAccessByEmailsToSelectedSecurityGroups = async ({
  clientId,
  values,
}: UpdateAccessManagementResponse) => {
  try {
    const { data } = await deploybotApi.post(`/graph/grant-access/${clientId}`, values, {
      params: { selected_params: '_id configs accessManagment clientAbbreviation' },
    });
    showToast('success', 'Your changes have been saved.');
    return data;
  } catch (error) {
    showToast('error', isAxiosError(error) ? error.response?.data?.message : error?.message);
    return undefined;
  }
};

export const fetchProductsDescription = async ({
  setProductsDescription,
  setLoadingProducts,
  clientId,
}: {
  setProductsDescription: React.Dispatch<React.SetStateAction<Report[]>>;
  setLoadingProducts: React.Dispatch<React.SetStateAction<boolean>>;
  clientId: string;
}) => {
  try {
    setLoadingProducts(true);
    const { data } = await deploybotApi.get<Report[]>(
      `/products/products-descriptions/${clientId}`,
    );
    setProductsDescription(data);
    setLoadingProducts(false);
    return data;
  } catch (error) {
    setLoadingProducts(false);
    throw new Error(error?.message);
  }
};
