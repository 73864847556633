import * as React from 'react';
import { SVGProps } from 'react';

const DigitalShelf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    {...props}
  >
    <path d="M21.75 7c3.558 0 5.994 2.657 6.304 6.162L28.213 15h3.334a1 1 0 0 1 .972.765l.02.113 1.365 11.128.2.01c3.239.197 6.7 2.34 9.295 5.343a2.538 2.538 0 0 1-.003 3.282c-2.67 3.093-6.252 5.283-9.57 5.357h-.623c-3.35-.074-6.932-2.264-9.602-5.36a2.484 2.484 0 0 1-.396-.637L11.823 35c-.856.006-1.448-.346-2.095-1.047-.533-.579-.765-1.286-.722-2.077l.018-.218L11 15.876a1 1 0 0 1 .877-.87L11.99 15h3.291c.053-.615.108-1.228.164-1.841C15.762 9.72 18.222 7 21.75 7zm11.766 22h-.035c-2.717-.047-5.958 1.876-8.36 4.66a.485.485 0 0 0-.068.104 1.003 1.003 0 0 1 .003.47.37.37 0 0 0 .062.1c2.4 2.784 5.624 4.714 8.398 4.665 2.74.049 5.964-1.881 8.363-4.661.161-.188.161-.49.003-.675-2.408-2.787-5.649-4.71-8.366-4.662zm-.017 1 .227.006A4 4 0 0 1 33.5 38a4 4 0 0 1 0-8zm.001 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM17.122 17c-.043.525-.085 1.05-.125 1.576a1 1 0 0 1-1.994-.152L15.115 17h-2.242L11.01 31.896c-.031.272.007.46.124.622l.064.08c.268.29.4.383.557.4l.06.002h11.389c.098-.229.231-.447.4-.644 2.327-2.696 5.36-4.7 8.301-5.222L30.662 17h-2.281l.033.4.083 1.02a1 1 0 1 1-1.994.16L26.372 17zm4.628-8c-2.402 0-4.084 1.86-4.312 4.341L17.288 15h8.917l-.143-1.662C25.837 10.798 24.188 9 21.75 9z" />
  </svg>
);
export default DigitalShelf;
