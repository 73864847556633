import * as React from 'react';
import { SVGProps } from 'react';

const MediaAnalyticsCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    viewBox="0 0 64 64"
    {...props}
  >
    <path d="M43 8.25c7.594 0 13.75 6.156 13.75 13.75S50.594 35.75 43 35.75a13.692 13.692 0 0 1-8.573-2.999L23 32.75c-5.494 0-5.688 8.082-.458 8.484l.231.012.227.004h18a1.75 1.75 0 0 1 1.744 1.606l.006.144v10a1.75 1.75 0 0 1-1.606 1.744L41 54.75H23c-9.803 0-17.75-7.947-17.75-17.75 0-9.693 7.77-17.571 17.42-17.747l.33-.003 6.525.003C30.797 12.975 36.347 8.25 43 8.25zm10.179 34.53a1.75 1.75 0 0 1 2.071 1.72v2.71l3.29-.898.147-.034a1.75 1.75 0 0 1 .773 3.41l-5.5 1.5-.139.032a1.75 1.75 0 0 1-2.071-1.72v-2.708l-3.29.896-.147.034a1.75 1.75 0 0 1-.773-3.41l5.5-1.5zM23 22.75c-7.87 0-14.25 6.38-14.25 14.25 0 7.765 6.21 14.08 13.936 14.247l.314.003h8.25v-6.5h-8.278l-.284-.005c-9.976-.325-9.863-15.193.01-15.49L23 29.25h8.315l-.054-.086a13.71 13.71 0 0 1-.764-1.434l-.034-.075a13.62 13.62 0 0 1-.701-1.926l-.015-.054a13.589 13.589 0 0 1-.401-2.044l-.005-.045a13.343 13.343 0 0 1-.06-.657l-.011-.177zm16.25 22h-4.5v6.5h4.5zM43 25.5a6.002 6.002 0 0 0-5.897 4.886C38.772 31.56 40.805 32.25 43 32.25s4.228-.69 5.896-1.864A6 6 0 0 0 43 25.5zm0-13.75c-5.472 0-9.943 4.288-10.235 9.688l-.011.277a10.681 10.681 0 0 0 .02 1l-.013-.236c.005.11.012.219.02.328l-.006-.091c.008.12.018.24.03.36l-.024-.27c.01.115.02.228.033.34l-.008-.07c.01.104.023.207.037.31l-.03-.24c.015.133.033.265.052.396l-.022-.156c.013.098.028.195.044.291l-.022-.135c.018.116.037.231.059.346l-.037-.21c.02.119.04.237.065.355l-.028-.145c.022.119.046.237.072.354l-.044-.209c.023.119.05.236.077.353l-.033-.144c.023.103.048.206.074.308l-.04-.164c.039.165.082.329.13.49l.097.319a10.193 10.193 0 0 0 .118.338l-.106-.303c.042.127.087.253.134.378l-.028-.075c.038.104.078.207.12.309l-.092-.234c.04.109.083.217.128.325l-.037-.091a10.193 10.193 0 0 0 .33.732l-.056-.115c.049.1.1.2.15.299l-.094-.184c.047.095.096.189.146.282l-.051-.098c.23.439.491.859.78 1.257a9.036 9.036 0 0 1 4.095-4.474 5.5 5.5 0 1 1 8.414 0 9.036 9.036 0 0 1 4.092 4.474A10.2 10.2 0 0 0 53.25 22c0-5.66-4.59-10.25-10.25-10.25zm0 5.75a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5z" />
  </svg>
);
export default MediaAnalyticsCard;
