import clsx from 'clsx';
import {
  clearIndicatorStyles,
  controlStyles,
  dropdownIndicatorStyles,
  groupHeadingStyles,
  indicatorSeparatorStyles,
  indicatorsContainerStyles,
  menuStyles,
  multiValueLabelStyles,
  multiValueRemoveStyles,
  multiValueStyles,
  noOptionsMessageStyles,
  optionStyles,
  placeholderStyles,
  selectInputStyles,
  singleValueStyles,
  valueContainerStyles,
} from 'components/Form/FormSelect/styles';
import _ from 'lodash';
import React, { KeyboardEventHandler, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { ISelectorOption } from 'types/ISelectorOption';
import {
  ClearIndicator,
  DropdownIndicator,
  MultiValueRemove,
  ReactSelectProps,
} from './ReactSelect';
import ErrorText from '../ErrorText';
import { showToast } from 'helpers/ToastHelper';
import { isAxiosError, AxiosResponse } from 'axios';
import Label from '../Label';

const MultiInput = ({
  error,
  onChange,
  apiCall,
  multiInputError,
  setMultiInputError,
  setValue,
  value,
  placeholder,
  secondaryApiCall,
  label,
  inputValue,
  setInputValue,
}: Omit<
  ReactSelectProps<
    string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosResponse<any, any>,
    { emails: string[] },
    AxiosResponse<
      [{ invitedUserEmailAddress: string; id: string; invitedUser: { id: string } }]
    >
  >,
  'options'
>) => {
  const [loadingApiCall, setLoadingApiCall] = React.useState(false);

  const createOption = (_label: string) => ({
    label,
    value: label,
  });

  const onSubmitEmails = async (email: string) => {
    try {
      const response = await apiCall(email);
      return response.data;
    } catch (_error) {
      if (
        _error.response.status === 500 &&
        (_error?.response.data as { message?: string })?.message === 'Cant find user'
      ) {
        try {
          const response = await secondaryApiCall?.({ emails: [email] });
          setLoadingApiCall(false);
          return {
            label: response.data?.[0].invitedUserEmailAddress,
            value: response.data?.[0].invitedUser.id,
          };
        } catch (secondaryError) {
          showToast('error', 'Invalid email address');
          setLoadingApiCall(false);
          return undefined;
        }
      }
      showToast(
        'error',
        isAxiosError(_error) ? _error.response?.data?.message : error?.message,
      );
      setLoadingApiCall(false);
      return undefined;
    }
  };

  const handleKeyDown: KeyboardEventHandler = async (event) => {
    try {
      setMultiInputError([]);
      if (!inputValue) return;
      const multiInputs = inputValue.trim().split(',' || ' ');
      if (event.key === 'Enter' || event.key === ' ' || event.key === 'Tab') {
        if (apiCall) {
          setLoadingApiCall(true);
          const response = await Promise.all(
            multiInputs.map(async (el) => {
              const result = await onSubmitEmails(el);
              return result;
            }),
          );
          setValue((prev) => _.uniqBy([...prev, ...response], 'label').filter(Boolean));
          setLoadingApiCall(false);
        } else {
          setValue((prev) =>
            _.uniqBy([...prev, ...multiInputs.map((el) => createOption(el))], 'label').filter(
              (el) => el.label,
            ),
          );
        }
        setInputValue('');
        event.preventDefault();
        setLoadingApiCall(false);
      }
    } catch (err) {
      setLoadingApiCall(false);
      return undefined;
    }
  };

  useEffect(() => {
    onChange(value, setValue);
  }, [value]);

  return (
    <div>
      <Label label={label} name={label} />
      <CreatableSelect
        styles={{
          input: (base) => ({
            ...base,
            'input:focus': {
              boxShadow: 'none',
            },
          }),
          multiValueLabel: (base) => ({
            ...base,
            whiteSpace: 'normal',
            overflow: 'visible',
          }),
          control: (base) => ({
            ...base,
            transition: 'none',
          }),
        }}
        unstyled
        components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
        classNames={{
          control: ({ isFocused, isDisabled }) =>
            clsx(
              error && !isFocused ? controlStyles.error : controlStyles.base,
              isFocused ? controlStyles.focus : controlStyles.nonFocus,
              isFocused && error ? controlStyles.focusWithError : controlStyles.base,
              controlStyles.base,
              isDisabled && controlStyles.disabledInputStyles,
            ),
          placeholder: () => placeholderStyles,
          input: ({ isDisabled }) =>
            isDisabled ? controlStyles.disabledInputStyles : selectInputStyles,
          valueContainer: () => valueContainerStyles,
          singleValue: () => singleValueStyles,
          multiValue: () => multiValueStyles,
          multiValueLabel: () => multiValueLabelStyles,
          multiValueRemove: () => multiValueRemoveStyles,
          indicatorsContainer: ({ isDisabled }) => !isDisabled && indicatorsContainerStyles,
          clearIndicator: () => clearIndicatorStyles,
          indicatorSeparator: () => indicatorSeparatorStyles,
          dropdownIndicator: () => dropdownIndicatorStyles,
          menu: () => menuStyles,
          groupHeading: () => groupHeadingStyles,
          option: ({ isFocused, isSelected }) =>
            clsx(
              isFocused && optionStyles.focus,
              isSelected && optionStyles.selected,
              optionStyles.base,
            ),
          noOptionsMessage: () => noOptionsMessageStyles,
        }}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={(newValue) => setValue(newValue as ISelectorOption[])}
        onInputChange={(newValue, action) => {
          if (action.action === 'input-change') {
            setInputValue(newValue);
          }
        }}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={value}
        autoFocus
        isLoading={loadingApiCall}
      />
      <ul>
        {multiInputError &&
          multiInputError.map((el) => (
            <li>
              <ErrorText key={el} error={el} />
            </li>
          ))}
      </ul>
    </div>
  );
};
export default MultiInput;
