import * as React from 'react';
import { SVGProps } from 'react';

const Modules = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    {...props}
  >
    <path d="M20.5 25.5a2 2 0 0 1 1.995 1.85l.005.15v10a2 2 0 0 1-1.85 1.995l-.15.005h-10a2 2 0 0 1-1.995-1.85L8.5 37.5v-10a2 2 0 0 1 1.85-1.995l.15-.005zm17 0a2 2 0 0 1 1.995 1.85l.005.15v10a2 2 0 0 1-1.85 1.995l-.15.005h-10a2 2 0 0 1-1.995-1.85l-.005-.15v-10a2 2 0 0 1 1.85-1.995l.15-.005zm-17 2h-10v10h10zm17 0h-10v10h10zm-17-19a2 2 0 0 1 1.995 1.85l.005.15v10a2 2 0 0 1-1.85 1.995l-.15.005h-10a2 2 0 0 1-1.995-1.85L8.5 20.5v-10a2 2 0 0 1 1.85-1.995l.15-.005zm17 0a2 2 0 0 1 1.995 1.85l.005.15v10a2 2 0 0 1-1.85 1.995l-.15.005h-10a2 2 0 0 1-1.995-1.85l-.005-.15v-10a2 2 0 0 1 1.85-1.995l.15-.005zm-17 2h-10v10h10zm17 0h-10v10h10z" />
  </svg>
);
export default Modules;
