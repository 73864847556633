export const controlStyles = (isProductPage: boolean) => ({
  base: `rounded-md border-none e hover:cursor-pointer hover:bg-default_gray font-worksans_medium px-2 !h-8 xl:!h-9 !min-h-[32px] xl:!min-h-[36px] bg-light_gray ${
    isProductPage ? 'bg-light_gray' : 'bg-white'
  }`,
  // base_
  focus: '!bg-secondary',
});

export const selectInputStyles = 'pl-1 py-0.5 font-worksans_medium';
export const valueContainerStyles =
  'pl-1 text-default_cool_gray text-sm font-worksans_medium ';
export const singleValueStyles = 'leading-7 ml-1 font-worksans_medium';
export const dropdownIndicatorStyles =
  'p-1 text-default_cool_gray rounded-md hover:text-black ';
export const menuStyles =
  'p-1 mt-2 border border-gray-200 bg-white rounded-lg text-sm shadow-lg font-worksans_medium z-50';
export const optionStyles =
  'hover:cursor-pointer px-3 py-2 rounded text-default_cool_gray font-worksans_medium hover:text-primary hover:font-bold hover:bg-gray-100 active:bg-gray-200';
