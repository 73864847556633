import { ClientAccess, NavigationType, Report } from 'Models/IReportsAccess';

export const findAccessReportsBySection = (
  selectedClient: ClientAccess,
  section: NavigationType,
) => {
  const access = selectedClient?.accessManagment?.find((el) => el.section === section);
  return access?.reports || [];
};

export const hasAccess = (selectedClient: ClientAccess, section: NavigationType) =>
  findAccessReportsBySection(selectedClient, section)?.length > 0;

export function filterReports(reports: Report[]): Report[] {
  const filteredReports: Report[] = reports?.reduce((accumulator, currentReport: Report) => {
    const existingReport = accumulator?.find(
      (report) =>
        report?.reportNavigationDisplayName === currentReport?.reportNavigationDisplayName,
    );

    if (!existingReport || !currentReport?.disabled) {
      accumulator.push(currentReport);
    }

    return accumulator;
  }, []);

  return filteredReports;
}

export const addProductDescriptionToReports = (reports: Report[], disabledReports: Report[]) =>
  reports.map((el) => {
    const found = disabledReports.find(
      (p) => p.reportNavigationDisplayName === el.reportNavigationDisplayName,
    );

    if (found) {
      return {
        ...el,
        reportNavigationDescription: found.reportNavigationDescription,
        productName: found.name,
        excludeCrossSelling: found?.excludeCrossSelling,
      };
    }
    return { ...el, productName: el.name };
  });
