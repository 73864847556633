import * as React from 'react';
import { SVGProps } from 'react';

const GeneralCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M49.778 12.444a5.333 5.333 0 0 1 5.333 5.334v28.444a5.333 5.333 0 0 1-5.333 5.334H14.222a5.333 5.333 0 0 1-5.333-5.334V17.778a5.333 5.333 0 0 1 5.333-5.334zm0 3.556H14.222c-.982 0-1.778.796-1.778 1.778v28.444c0 .982.796 1.778 1.778 1.778h35.556c.982 0 1.778-.796 1.778-1.778V17.778c0-.982-.796-1.778-1.778-1.778zm-3.806 16.868c.466.784.261 1.78-.446 2.321l-.172.116-9.334 5.556c-.279.166-.597.252-.919.25l-.241-.018-7.356-1.052-7.372 2.53a1.778 1.778 0 0 1-2.18-.913l-.078-.193a1.778 1.778 0 0 1 .913-2.18l.192-.078 7.778-2.666c.2-.069.409-.101.618-.096l.21.017 7.155 1.022 8.795-5.234a1.778 1.778 0 0 1 2.437.618zM19.556 20.444a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334zm17.777.89a1.778 1.778 0 0 1 .208 3.543l-.208.012H26.667a1.778 1.778 0 0 1-.208-3.544l.208-.012z"
    />
  </svg>
);
export default GeneralCard;
