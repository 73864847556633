import BespokeSolutionsCard from 'assets/svg/BespokeSolutionsCard';
import DigitalShelfCard from 'assets/svg/DigitalShelfCard';
import GeneralCard from 'assets/svg/GeneralCard';
import MediaAnalyticsCard from 'assets/svg/MediaAnalyticsCard';
import MultichannelCard from 'assets/svg/MultiChannelCard';
import RetailAnalyticsCard from 'assets/svg/RetailAnalyticsCard';

export const getLeftIconComponent = (section: string) => {
  switch (section) {
    case 'Retail':
      return RetailAnalyticsCard;
    case 'Media':
      return MediaAnalyticsCard;
    case 'Digital Shelf':
      return DigitalShelfCard;
    case 'General Reports':
      return GeneralCard;
    case 'Custom':
      return BespokeSolutionsCard;
    default:
      return MultichannelCard;
  }
};
