import ArrowCard from 'assets/svg/ArrowCard';
import Locked from 'assets/svg/Locked';
import Unlocked from 'assets/svg/Unlocked';
import { getLeftIconComponent } from 'helpers/NavigationHelper';
import { useMemo, useState } from 'react';

const useNavigationCard = ({ section, disabled }: { section: string; disabled: boolean }) => {
  const [isHovered, setIsHovered] = useState(false);

  const LeftIconComponent = useMemo(() => getLeftIconComponent(section), [section]);

  const rightIcon = useMemo(() => {
    if (disabled) {
      return isHovered ? (
        <Unlocked className="fill-primary w-[40px] h-[40px] md:w-[48px] md:h-[48px] xl:w-[56px] xl:h-[56px]" />
      ) : (
        <Locked className="fill-default_dark_gray w-[40px] h-[40px] md:w-[48px] md:h-[48px] xl:w-[56px] xl:h-[56px]" />
      );
    }
    if (isHovered) {
      return <ArrowCard className="w-[40px] h-[40px] md:w-[48px] md:h-[48px]" />;
    }
    return null;
  }, [disabled, isHovered]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return {
    handleMouseEnter,
    handleMouseLeave,
    rightIcon,
    LeftIconComponent,
    isHovered,
  };
};

export default useNavigationCard;
