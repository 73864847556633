import { XMarkIcon } from '@heroicons/react/20/solid';
import { useMemo, useState } from 'react';

const Banner = () => {
  const bannerProps = useMemo(() => {
    try {
      const parsedBanner = JSON.parse(process.env.REACT_APP_BANNER) as {
        message: 'string';
        type: 'warning' | 'info' | 'success' | 'error';
        link?: string;
      };
      let color = '';
      let textColor = '';
      switch (parsedBanner.type) {
        case 'error':
          color = 'bg-burgundy_light_semi';
          textColor = 'text-white';
          break;
        case 'info':
          textColor = 'text-white';
          color = 'bg-primary';
          break;
        case 'success':
          color = 'bg-green-500';
          break;
        case 'warning':
          color = 'bg-secondary';
          textColor = 'text-black';
          break;

        default:
          color = 'bg-white';
          break;
      }
      return {
        message: parsedBanner.message,
        link: parsedBanner.link,
        color,
        textColor,
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, [process.env.REACT_APP_BANNER]);

  const [showBanner, setShowBanner] = useState(true);

  const hideBanner = () => {
    setShowBanner(false);
  };

  return (
    <div>
      {bannerProps?.message && showBanner && (
        <div
          className={`flex   items-center gap-x-6 ${bannerProps.color} px-6 py-2.5 sm:px-3.5 sm:before:flex-1 `}
        >
          <p
            className={`text-sm leading-6 flex content-center ${bannerProps.textColor} font-worksans_medium`}
          >
            {/* Join us in Denver from June 7 – 9 to see what’s coming next&nbsp; */}
            <a href={bannerProps.link}>{bannerProps.message}</a>
          </p>
          <div className="flex flex-1 justify-end">
            <button
              type="button"
              className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
              onClick={hideBanner}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
