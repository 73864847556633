import './styles.css';

interface ISpinner {
  className?: string;
}

const Spinner: React.FC<ISpinner> = ({ className }) => (
  <div className={className || 'absolute inset-0 flex items-center justify-center'}>
    <div className="dot-spinner">
      <div className="dot-spinner__dot" />
      <div className="dot-spinner__dot" />
      <div className="dot-spinner__dot" />
      <div className="dot-spinner__dot" />
      <div className="dot-spinner__dot" />
      <div className="dot-spinner__dot" />
      <div className="dot-spinner__dot" />
      <div className="dot-spinner__dot" />
    </div>
  </div>
);

export default Spinner;
