import * as React from 'react';
import { SVGProps } from 'react';

const DigitalShelfCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    viewBox="0 0 64 64"
    {...props}
  >
    <path d="M28.889 8.25c4.965 0 9.226 4.057 9.847 9.033l.126 1.01.234 1.957H44c.88 0 1.622.653 1.735 1.525l.819 6.359.761 6.08.317 2.652.055.475c4.609.549 9.434 3.527 13.088 7.592 1.3 1.46 1.3 3.67-.005 5.136-4.105 4.572-9.657 7.767-14.77 7.68h-.377c-4.764-.075-9.846-2.917-13.76-6.998l-17.81-.05c-1.484.01-2.833-.387-3.751-1.4-.862-.956-1.23-1.904-1.144-3.152l.023-.253 3.123-24.12a1.75 1.75 0 0 1 1.581-1.52l.154-.006h4.862l.237-1.956.126-1.011c.626-5.015 4.684-9.033 9.625-9.033zm17.46 32.501h-.7c-3.862.08-8.414 2.726-11.81 6.509a.361.361 0 0 0-.005.471c3.395 3.783 7.948 6.457 11.815 6.518h.702c3.89-.08 8.417-2.734 11.81-6.513a.361.361 0 0 0 .006-.47c-3.406-3.788-7.982-6.452-11.818-6.515zM45.999 42l.273.007A5.499 5.499 0 0 1 51.5 47.5 5.5 5.5 0 1 1 45.999 42zM46 45a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM18.493 23.75h-2.917l-2.92 22.566-.015.173c-.002.118.023.187.114.3l.143.165c.101.111.46.225.97.244l.176.002 16.215.045a3.859 3.859 0 0 1 .97-2.318c3.614-4.025 8.368-6.974 12.934-7.567l-.31-2.63-.679-5.43-.712-5.55h-2.957l.109.937.125 1.12a1.75 1.75 0 1 1-3.478.386l-.125-1.114-.153-1.329H22.015l-.026.23-.25 2.213a1.75 1.75 0 1 1-3.478-.386zm10.396-12c-3.084 0-5.735 2.625-6.153 5.967l-.249 2.025-.061.508h13.146l-.184-1.528-.124-1.005c-.41-3.278-3.234-5.967-6.375-5.967z" />
  </svg>
);
export default DigitalShelfCard;
