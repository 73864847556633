import { ClientAccess, ReportsAccess } from 'Models/IReportsAccess';
import { clientApi } from 'api';
import React from 'react';

export const fetchPermissions = async ({
  setNavigationData,
  setSelectedClient,
  token,
  setLoading,
  clientQuery,
}: {
  setNavigationData: React.Dispatch<React.SetStateAction<ReportsAccess>>;
  setSelectedClient: React.Dispatch<React.SetStateAction<ClientAccess>>;
  token: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  clientQuery: string;
}) => {
  try {
    setLoading(true);
    const { data } = await clientApi.post<ReportsAccess>('permissions', { token });
    setNavigationData(data);
    setSelectedClient(
      data?.reports_accesses.find((client) => client.client === clientQuery) ||
        data?.reports_accesses[0],
    );
    setLoading(false);
    return {
      navigationData: data,
      selectedClient:
        data?.reports_accesses.find((client) => client.client === clientQuery) ||
        data?.reports_accesses[0],
    };
  } catch (error) {
    setLoading(false);
    throw new Error(error?.message);
  }
};
