import PageTitle from 'components/PageTitle';
import PageContainer from 'containers/PageContainer';

const RetailAnalyticsPage = () => (
  <PageContainer
    navigationSection="Retail"
    title={
      <PageTitle
        title="Amazon Retail"
        subtitle="Tools for your sales team to increase revenue and decrease cost."
      />
    }
  />
);

export default RetailAnalyticsPage;
