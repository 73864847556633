import { UserPlusIcon } from '@heroicons/react/24/outline';
import { getUserByEmail, inviteExternalUser } from 'api/requests/deploybot';
import Button from 'components/Button';
import MultiInput from 'components/Form/FormSelect/MultiInput';
import MultiToolTip from 'components/MultiToolTip';
import React, { useEffect, useState } from 'react';
import { ISelectorOption } from 'types/ISelectorOption';

interface AddEmailsFormProps {
  onSubmit: (chips: ISelectorOption[]) => void;
  setSelectedEmails: React.Dispatch<React.SetStateAction<ISelectorOption[]>>;
  selectedEmails: ISelectorOption[];
  setMultiInputError: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  multiInputError?: string[];
  className?: string;
  extraInput?: React.ReactNode;
  exportButton?: React.ReactNode;
  cancelSliderOver?: () => void;
  selectedReports?: ISelectorOption[];
  reportsFocus?: boolean;
}
const AddEmailsForm: React.FC<AddEmailsFormProps> = ({
  selectedEmails,
  setSelectedEmails,
  onSubmit,
  setMultiInputError,
  multiInputError,
  className,
  extraInput,
  cancelSliderOver,
  selectedReports,
}) => {
  const onChange = async (emails: ISelectorOption[]) => {
    setSelectedEmails(emails);
  };
  const [inputValue, setInputValue] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (!inputValue) {
      setShowAlert(false);
    } else {
      setShowAlert(true);
    }
  }, [inputValue]);

  return (
    <div className={`${className}`}>
      <div>
        <MultiInput
          setMultiInputError={setMultiInputError}
          label="Enter email address(es) and press space to confirm:"
          multiInputError={multiInputError}
          apiCall={getUserByEmail}
          secondaryApiCall={inviteExternalUser}
          onChange={onChange}
          value={selectedEmails}
          setValue={setSelectedEmails}
          setInputValue={setInputValue}
          inputValue={inputValue}
          placeholder="Enter one or more email addresses"
        />
        {extraInput}
        <div className="right-0 w-full ">
          <div className="flex justify-end space-x-3 items-end pt-3">
            <Button
              type="button"
              main="Cancel"
              btnType="light"
              textStyles="text-primary"
              onClick={cancelSliderOver}
            />

            <div>
              {showAlert || (!inputValue && selectedEmails.length === 0) ? (
                <MultiToolTip
                  title={
                    !inputValue && selectedEmails.length === 0
                      ? 'Enter an email address'
                      : showAlert
                      ? 'Confirm last email address by pressing space.'
                      : ''
                  }
                  id="info_tooltip"
                  place="top"
                  hoverComponent={
                    <Button
                      onClick={() => onSubmit(selectedEmails)}
                      disabled={selectedEmails.length === 0 || selectedReports.length === 0}
                      btnType="primary"
                      type="button"
                      main={
                        <div className="flex items-center">
                          <div>Add users</div>
                          <UserPlusIcon className="h-4 w-4 ms-1" aria-hidden="true" />
                        </div>
                      }
                    />
                  }
                />
              ) : (
                <Button
                  onClick={() => onSubmit(selectedEmails)}
                  disabled={selectedEmails.length === 0}
                  btnType="primary"
                  type="button"
                  main={
                    <div className="flex items-center">
                      <div>Add users</div>
                      <UserPlusIcon className="h-4 w-4 ms-1" aria-hidden="true" />
                    </div>
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEmailsForm;
